import defaultAxiosInstance from './axios';
import shareService from './ShareService';
import apiClientHelper from '../clients/helper';
import { statusCodes } from '../constants/uploadFileStatuses';

class UploadService {
  constructor(axiosInstance = defaultAxiosInstance) {
    this.cancelUploadController = new AbortController();
    this.axiosCancelUploadConfig = { signal: this.cancelUploadController.signal };
    this.uploadClientInstance = this.getApiClient(axiosInstance);
    this.shareService = shareService();
  }

  cancelUpload() {
    this.cancelUploadController.abort();
  }

  getApiClient(axiosInstance) {
    return apiClientHelper.selectUploaApiCLient(axiosInstance);
  }

  async createUploadSession(uploadId, fileName, displayName, fileUrl, uploadProgressConfig) {
    const session = await this.uploadClientInstance.createSession(fileName);
    const objectName = session.ossFileName;

    const { uploadUrl, uploadKey } = await this.uploadClientInstance.getUploadUrl(objectName);
    await this.uploadClientInstance.uploadFile(uploadId, fileUrl, uploadUrl, uploadProgressConfig);
    const completeUploadResp = await this.uploadClientInstance.completeUpload(objectName, uploadKey);

    return this.createShare(session, completeUploadResp, displayName);
  }

  async createShare(session, ossResponse, displayName) {
    let share;
    try {
      const data = {
        ossUploadObjectId: ossResponse.objectId,
        ossUploadSize: ossResponse.size.toString(),
        uploadSessionTokenId: session.id,
        displayName,
      };

      share = await this.shareService.createShare(data, this.axiosCancelUploadConfig);
    } catch (error) {
      throw new Error(statusCodes.CREATE_SHARE_FAILED, { cause: error });
    }

    return share;
  }
}

export default UploadService;
