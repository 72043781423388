import { styled } from '@mui/material/styles';
import { themes } from '../../constants/hostConfig';

export const AppWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '99.5vh',
  backgroundColor: theme.name === themes.LIGHT ? theme.palette.grey[100] : theme.palette.background.paper,
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  minWidth: '300px',
  margin: 'auto',
}));

export const ViewWrapper = styled('div')({ display: 'flex', flexDirection: 'column', minHeight: '0' });

export const ViewList = styled('div')({ overflowY: 'auto', overflowX: 'hidden' });
