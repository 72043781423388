import copyToClipboard from 'copy-to-clipboard';
import defaultAxiosInstance from './axios';
import apiClientHelper from '../clients/helper';
import store from '../stores';
import { expiryDate, getAPISortOrder } from '../utils/sort';
import { update } from '../utils/updateObject';
import { getCreateShareUrl } from '../clients/upload/utils';

class ShareService {
  constructor(axiosInstance = defaultAxiosInstance) {
    if (ShareService.instance) {
      return ShareService.instance;
    }
    this.axios = axiosInstance;
    this.apiClient = apiClientHelper.selectShareApiClient(this.axios);
    ShareService.instance = this;
  }

  static instance;

  createShare(data, requestConfig) {
    return this.axios.post(getCreateShareUrl(), data, requestConfig).then((response) => response.data);
  }

  getShares(pageCount = 1) {
    const { sortBy } = store.getState();
    const sortOrder = getAPISortOrder(sortBy);
    return this.apiClient.getShares(pageCount, sortBy, sortOrder).then((response) => {
      const { setShares } = store.getState();
      if (pageCount === 1) setShares([]);
      const { shares } = store.getState();
      setShares([...shares, ...response]);
      if (response.length === 25) {
        return this.getShares(pageCount + 1);
      }
      return [...shares, ...response];
    });
  }

  deleteShare(share) {
    return this.apiClient.deleteShare(share.viewerId).then((res) => {
      const { shares, setShares, setShareToDelete } = store.getState();
      const updatedShares = shares.filter((shareItem) => shareItem.viewerId !== share.viewerId);
      setShares(updatedShares);
      setShareToDelete({});
      return res;
    });
  }

  extendShare(share) {
    return this.apiClient.extendShare(share.viewerId).then((expires) => {
      const { shares, setShares, changeSortBy, sortBy } = store.getState();
      const updatedShares = shares.map((shareItem) => {
        if (share.viewerId === shareItem.viewerId) {
          return update(shareItem, { expires });
        }

        return shareItem;
      });
      setShares(updatedShares);
      if (sortBy === expiryDate) {
        changeSortBy(expiryDate);
      }
      return expires;
    });
  }

  getShareUrl(share) {
    return this.apiClient.getShareUrl(share.viewerId).then(copyToClipboard);
  }
}

const shareService = () => new ShareService();

export default shareService;
