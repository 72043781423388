import lightErrorServiceDown from './light-error-service-down.png';
import lightErrorUnknown from './light-error-unknown.png';

import darkErrorUnknown from './dark-error-unknown.png';
import darkErrorServiceDown from './dark-error-service-down.png';

import darkBlueErrorServiceDown from './dark-blue-error-service-down.png';
import darkBlueErrorUnknown from './dark-blue-error-unknown.png';

import { themes } from '../../constants/hostConfig';

export const errorServiceDownImages = {
  [themes.LIGHT]: lightErrorServiceDown,
  [themes.DARK]: darkErrorServiceDown,
  [themes.DARK_BLUE]: darkBlueErrorServiceDown,
};

export const errorUnknownImages = {
  [themes.LIGHT]: lightErrorUnknown,
  [themes.DARK]: darkErrorUnknown,
  [themes.DARK_BLUE]: darkBlueErrorUnknown,
};
