/**
 * Header of the application. It renders:
 * - Visit adsk button
 * - If current view is 'Shares': Search
 * - Sync button
 * - Help button
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import SyncIcon from '@mui/icons-material/Sync';
import HelpIcon from '@mui/icons-material/Help';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Search from './Search';
import host from '../../services/HostWrapper';
import { HeaderActions, SearchWrapper } from './styled';
import { launchImages } from '../../img/util';
import useStoreUpdate from '../../hooks/useStoreUpdate';
import useShallowStore from '../../hooks/useShallowStore';
import { launchDarklyMetrics } from '../../constants/analyticsConfig';

const Header = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { shareId } = useParams();
  const ldClient = useLDClient();

  const { generalUpdate } = useStoreUpdate();

  const { isLoading } = useShallowStore((state) => ({
    isLoading: state.isLoading,
  }));

  const handleRefreshClick = () => {
    if (!isLoading) generalUpdate();
  };

  const search = !shareId && <Search id="app-header-search" />;
  return (
    <AppBar position="static">
      <Toolbar disableGutters>
        <Tooltip title={t('visit-a360')}>
          <IconButton
            onClick={() => {
              ldClient.track(launchDarklyMetrics.OPEN_IN_BROWSER);
              host.launchBrowser('https://viewer.autodesk.com');
            }}
          >
            <img src={launchImages[theme.name]} alt="visit adsk viewer" />
          </IconButton>
        </Tooltip>
        <SearchWrapper>{search}</SearchWrapper>
        <HeaderActions>
          <IconButton data-testid="refresh-button" onClick={handleRefreshClick}>
            <SyncIcon />
          </IconButton>
          <IconButton onClick={host.launchHelp}>
            <HelpIcon />
          </IconButton>
        </HeaderActions>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
