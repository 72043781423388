import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

const createTextComponent = (variant) => {
  const TextComponent = ({ children, ...rest }) => (
    <Typography variant={variant} noWrap {...rest}>
      {children}
    </Typography>
  );

  TextComponent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  };

  return TextComponent;
};

export const BodyText = createTextComponent('body2');

export const Caption = createTextComponent('caption');

export const Subtitle = createTextComponent('subtitle2');

export const Title = createTextComponent('h4');
