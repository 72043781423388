import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material/styles';
import Comment from '../Comment';
import Info from '../Info';
import { noCommentsImages } from '../../img/missing';
import useShallowStore from '../../hooks/useShallowStore';

import { NoCommentWrapper } from './styled';

/**
 *
 * @returns {JSX.Element}
 */

const CommentList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { comments, showResolvedComments } = useShallowStore((state) => ({
    comments: state.comments,
    showResolvedComments: state.showResolvedComments,
  }));
  if (comments?.length > 0) {
    return comments.map(
      (comment) =>
        // return <Comment /> if not resolved; or resolved but with showResolvedComments flag
        (!comment.isResolved || (comment.isResolved && showResolvedComments)) && (
          <Comment key={comment.commentId} comment={comment} />
        )
    );
  }

  // This shared view has no comments
  return (
    <NoCommentWrapper>
      <Info img={noCommentsImages[theme.name]} title={t('comment-none')} />
    </NoCommentWrapper>
  );
};

export default CommentList;
