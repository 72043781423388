// Comment object
class CommentModel {
  constructor({
    author,
    text,
    createdAt,
    commentId,
    linkToComment = '',
    isResolved = false,
    attachmentUrl,
    replies = [],
  }) {
    this.author = author;
    this.text = text;
    this.createdAt = createdAt;
    this.commentId = commentId;
    this.linkToComment = linkToComment;
    this.isResolved = isResolved;
    this.attachmentUrl = attachmentUrl;
    this.replies = replies;

    // Will be set later
    this.attachmentImage = undefined;
  }
}

export default CommentModel;
