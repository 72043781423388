import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import Info from '../../Info';
import ActionItem from '../../../models/ActionItem';
import { errorUnknownImages } from '../../../img/error';

const ErrorUnknown = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Info
      img={errorUnknownImages[theme.name]}
      title={t('error-unknown')}
      msg={t('please-refresh')}
      buttons={[new ActionItem(t('error-info-refresh'), () => navigate('/'))]}
    />
  );
};

export default ErrorUnknown;
