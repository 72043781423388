import { styled } from '@mui/material/styles';

import Select from '@mui/material/Select';

/**
 * @template {{}} Props
 * @typedef {import('@emotion/styled').StyledComponent<Props>} StyledComponent<Props>
 */

/**
 * @type {StyledComponent<'div'>}
 */
export const SharesViewWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  minHeight: '0',
});

/**
 * @type {StyledComponent<'div'>}
 */
export const SortByWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-end',
  alignItems: 'center',
  width: '40%',
  margin: theme.spacing(3, 0.5, 1),
}));

/**
 * @type {StyledComponent<'span'>}
 */
export const MenuItemText = styled('span')(({ theme }) => ({ paddingLeft: theme.spacing(0.5) }));

/**
 * @type {StyledComponent<import('@mui/material').Select>}
 */
export const SortBySelect = styled(Select)(({ theme }) => ({
  '&&.MuiOutlinedInput-root': {
    backgroundColor: `${theme.palette.background.default}`,
    border: 'transparent',
  },
  '&& .MuiSelect-select': {
    backgroundColor: `${theme.palette.background.default}!important`,
    border: 'transparent',
  },
  '&& .MuiOutlinedInput-input': {
    fontSize: '0.875rem',
  },
  '&& .MuiOutlinedInput-input:hover': {
    boxShadow: 'none',
  },
}));

/**
 * @type {StyledComponent<'div'>}
 */
export const ClickableShareWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(0.5),
  cursor: 'pointer',

  '&:hover': {
    outline: `2px solid ${theme.palette.divider}`,
  },
  '&:active, &:focus': {
    outline: `1px solid ${theme.palette.divider}`,
  },
}));

/**
 * @type {StyledComponent<'div'>}
 */
export const NoMatchFoundWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
}));
