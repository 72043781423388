export const createdAt = 'Created';
export const title = 'DesignTitle';
export const expiryDate = 'ExpirationDate';

// Returns the sortOrder parameter for API calls
export const getAPISortOrder = (sortBy) => {
  let sortOrder = 'Descending';
  switch (sortBy) {
    case createdAt:
      sortOrder = 'Descending';
      break;
    case title:
      sortOrder = 'Ascending';
      break;
    case expiryDate:
      sortOrder = 'Ascending';
      break;
    default:
      break;
  }
  return sortOrder;
};
