/**
 * Share renders
 * - Shared view info
 * - Shared viewthumb
 * - If the shared view have comments: shared View comment number
 * - If the shared view is in the CommentsView: `add comment` link
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import MenuIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { getExpiryDays } from '../../utils/time';
import Dropdown from '../Dropdown';
import ActionItem from '../../models/ActionItem';
import ModelThumbnail from '../ModelThumbnail';
import { Caption, Subtitle, BodyText } from '../Text';
import { sharesStatuses } from '../../constants/shares';
import { preloadImages } from '../../img/util';
import host from '../../services/HostWrapper';
import useShallowStore from '../../hooks/useShallowStore';
import useErrorHook from '../../hooks/useErrorHook';

import {
  ShareContent,
  ShareImage,
  ShareInfo,
  ShareTitle,
  ShareDescription,
  CommentsAmount,
  CommentIcon,
  ProcessingFailed,
} from './styled';
import adpInstance from '../../utils/adp';
import { componentEvents, launchDarklyMetrics } from '../../constants/analyticsConfig';
import shareService from '../../services/ShareService';

/**
 * @typedef {import('../../models/ShareModel').default} ShareModel
 * @typedef {import('../../models/ActionItem').default} Action
 */

/**
 * @param {Object} props
 * @param {ShareModel} props.share
 * @param {() => void} [props.handleAddCommentClick]
 * @param {Action[]} [props.actionItems=[]]
 * @returns {JSX.Element}
 */
const Share = ({ handleAddCommentClick, actionItems = [], share }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setShareToDelete } = useShallowStore((state) => ({
    setShareToDelete: state.setShareToDelete,
  }));
  const handleError = useErrorHook();
  const ldClient = useLDClient();

  const handleThumbClick = (e) => {
    // do not bubble this event further up
    e.stopPropagation();
    ldClient?.track(launchDarklyMetrics.OPEN_IN_BROWSER);
    host.launchBrowser(share.a360link);
  };

  // If the shared view has any comments, render the number of comments
  const numComments = share.numComments > 0 && (
    <IconButton>
      <CommentIcon />
      <CommentsAmount>
        {share.numComments} {share.numComments > 1 ? t('design-share-comments') : t('design-share-comment')}
      </CommentsAmount>
    </IconButton>
  );

  const expires = getExpiryDays(share.expires);
  const isFailed = share.status === sharesStatuses.FAILED;
  const handleShareDelete = () => {
    ldClient?.track(launchDarklyMetrics.DELETE_SHARE_PROMPT);
    adpInstance.trackComponentEvent(componentEvents.DELETE_SHARE_PROMPT);
    setShareToDelete(share);
  };
  return (
    <ShareContent>
      <ShareTitle>
        <Subtitle title={share.title}>{share.title}</Subtitle>
        {isFailed ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleShareDelete();
            }}
            data-testid="delete-failed-share"
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <Dropdown
            button={<MenuIcon />}
            actionItems={[
              ...actionItems,
              new ActionItem(t('design-share-view-in-browser'), (e) => {
                ldClient?.track(launchDarklyMetrics.OPEN_IN_BROWSER);
                adpInstance.trackComponentEvent(componentEvents.OPEN_IN_BROWSER);
                handleThumbClick(e);
              }),
              new ActionItem(t('design-share-get-link'), () => {
                ldClient?.track(launchDarklyMetrics.COPY_LINK);
                adpInstance.trackComponentEvent(componentEvents.COPY_LINK);
                shareService().getShareUrl(share).catch(handleError);
              }),
              new ActionItem(t('design-share-extend'), () => {
                ldClient?.track(launchDarklyMetrics.EXTEND_SHARE);
                adpInstance.trackComponentEvent(componentEvents.EXTEND_SHARE);
                shareService().extendShare(share).catch(handleError);
              }),
              new ActionItem(t('design-share-delete'), handleShareDelete),
            ]}
          />
        )}
      </ShareTitle>
      <ShareInfo>
        <ShareImage>
          <ModelThumbnail
            image={isFailed ? preloadImages[theme.name] : share.thumbnail}
            handleClick={handleThumbClick}
            viewableId={share.viewerId}
          />
        </ShareImage>
        <ShareDescription>
          <div>
            <BodyText>{`${t('design-share-updated')} ${moment(share.createdAt).format('LL')}`}</BodyText>
            {isFailed ? (
              <ProcessingFailed>{t('processing-failed')}</ProcessingFailed>
            ) : (
              <Caption>
                {`${t('design-share-expires')} ${expires} ${expires && expires > 1 ? t('time-days') : t('time-day')}`}
              </Caption>
            )}
          </div>
          {!isFailed && (
            <div>
              {handleAddCommentClick ? (
                <Link href="#" onClick={handleAddCommentClick}>
                  {t('comment-add')}
                </Link>
              ) : (
                numComments
              )}
            </div>
          )}
        </ShareDescription>
      </ShareInfo>
    </ShareContent>
  );
};

export default Share;
