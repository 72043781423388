import lightPreload from './light-preload.png';
import lightLaunch from './light-launch.png';

import darkPreload from './dark-preload.png';
import darkLaunch from './dark-launch.png';

import darkBluePreload from './dark-blue-preload.png';
import darkBlueLaunch from './dark-blue-launch.png';

import { themes } from '../../constants/hostConfig';

export const preloadImages = {
  [themes.LIGHT]: lightPreload,
  [themes.DARK]: darkPreload,
  [themes.DARK_BLUE]: darkBluePreload,
};

export const launchImages = {
  [themes.LIGHT]: lightLaunch,
  [themes.DARK]: darkLaunch,
  [themes.DARK_BLUE]: darkBlueLaunch,
};
