import envConfig from 'environmentConfig';
import baseConfig from 'configs/envs/base.config.json';
import defaultAxiosInstance from '../services/axios';
import UserModel from '../models/UserModel';

class UserClient {
  constructor(axiosInstance = defaultAxiosInstance) {
    this.axios = axiosInstance;

    this.userInfoUrl = `${envConfig.get().viewerApi.url}${baseConfig.viewerApi.v1.userInfo}`;
  }

  getUserProfile() {
    return this.axios.get(this.userInfoUrl).then(
      (response) =>
        new UserModel({
          id: response.data.sub,
          name: response.data.name,
          email: response.data.email,
        })
    );
  }
}

export default UserClient;
