import { Adp } from '@adsk/adp-web-analytics-sdk';
import envConfig from 'environmentConfig';
import { events } from '../constants/analyticsConfig';
import host from '../services/HostWrapper';

class AdpTracking {
  constructor() {
    this.adpInstance = null;
  }

  initializeTrackingClient({ user, enableAdp }) {
    const { autodeskDataPlatform } = envConfig.get();
    const regFacets = {
      user: {
        provider_name: 'o2',
        user_id: user.id,
        logged_in: true,
      },
      product: {
        name: 'SharedViews',
        id_provider: 'appkey',
        id: autodeskDataPlatform.clientID,
      },
    };
    const trackingConfig = {
      env: autodeskDataPlatform.env,
      enable_geo_data: false,
      enable_browser_data: true,
      enable_session_messages: true,
      test: {
        enable_upload: enableAdp,
      },
    };
    this.adpInstance = Adp(regFacets, trackingConfig, { retrieveUserToken: () => host.getOAuth2Token() });
  }

  trackEvent(event, facets) {
    this.adpInstance.trackEvent(event, facets);
  }

  trackComponentEvent(name) {
    this.trackEvent(events.CLICK_COMPONENT, {
      component: {
        name,
      },
    });
  }
}

const adpInstance = new AdpTracking();
export default adpInstance;
