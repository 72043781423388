import envConfig from 'environmentConfig';
import baseConfig from 'configs/envs/base.config.json';
import defaultAxiosInstance from '../../services/axios';

class ViewerApiCommentClient {
  constructor(axiosInstance = defaultAxiosInstance) {
    this.axios = axiosInstance;
    this.viewerApiUrl = envConfig.get().viewerApi.url;
    this.viewerApiCdn = envConfig.get().viewerApi.cdn;
  }

  getComments(viewableId) {
    return this.axios
      .get(this.viewerApiUrl + baseConfig.viewerApi.v2.getComments, { params: { viewableId } })
      .then((response) => response.data.comments);
  }

  reply(commentId, reply) {
    return this.axios.put(this.viewerApiUrl + baseConfig.viewerApi.v2.putReply.replace('{id}', commentId), {
      body: reply,
    });
  }

  resolve(commentId, data) {
    return this.axios.put(this.viewerApiUrl + baseConfig.viewerApi.v1.resolveComment.replace('{id}', commentId), data);
  }
}

export default ViewerApiCommentClient;
