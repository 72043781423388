import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTheme } from '@mui/material/styles';

import Loader from '../Loader';
import ThumbnailService from '../../services/ThumbnailService';
import { ImageButton, Image, ModelThumbnailWrapper } from './styled';
import { preloadImages } from '../../img/util';
import retry, { shouldRetryFetching } from '../../utils/thumbnails';
import { sharesStatuses } from '../../constants/shares';
import useCurrentShare from '../../hooks/useCurrentShare';
import newRelicHelper from '../../utils/newRelic/helper';

const ModelThumbnail = ({ image, handleClick, viewableId = '', isCommentThumbnail = false }) => {
  const theme = useTheme();
  const share = useCurrentShare(viewableId);
  const ref = useRef();
  const [renderedImage, setRenderedImage] = useState(image);

  const defaultValue = isCommentThumbnail ? null : preloadImages[theme.name];
  const thumbnailService = new ThumbnailService();

  useEffect(() => {
    setRenderedImage(image);
  }, [image]);

  useEffect(() => {
    return () => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
    };
  }, []);

  const handleError = (retryCount = 0) => {
    setRenderedImage(defaultValue);
    const { FAILED } = sharesStatuses;
    const { status } = share;
    if (status === FAILED || !shouldRetryFetching(share)) return;

    thumbnailService
      .getThumbnailBlob(image)
      .then((blob) => {
        setRenderedImage(URL.createObjectURL(blob));
      })
      .catch((error) => {
        const responseError = error ? error.response : null;
        const statusCode = responseError?.status;
        if (shouldRetryFetching(share, statusCode)) {
          ref.current = retry(handleError, retryCount);
        }
        newRelicHelper.trackError(error);
      });
  };

  return (
    <ModelThumbnailWrapper>
      {renderedImage ? <Image src={renderedImage} alt="thumbnail" onError={() => handleError()} /> : <Loader />}
      <ImageButton data-testid="model-thumbnail-clickable-wrapper" onClick={handleClick}>
        <VisibilityIcon />
      </ImageButton>
    </ModelThumbnailWrapper>
  );
};

ModelThumbnail.propTypes = {
  handleClick: PropTypes.func.isRequired,
  image: PropTypes.string,
  viewableId: PropTypes.string,
  isCommentThumbnail: PropTypes.bool,
};

export default ModelThumbnail;
