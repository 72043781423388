import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import moment from 'moment';
import Checkbox from '@mui/material/Checkbox';

import ReplyList from './ReplyList';
import { Caption, Title } from '../Text';

import ModelThumbnail from '../ModelThumbnail';
import host from '../../services/HostWrapper';
import useErrorHook from '../../hooks/useErrorHook';
import {
  CommentHeader,
  CommentInfo,
  CommentText,
  CommentWrapper,
  ModelThumbnailWrapper,
  ResolveLabel,
  ResolveLabelText,
} from './styled';
import adpInstance from '../../utils/adp';
import { componentEvents, launchDarklyMetrics } from '../../constants/analyticsConfig';
import commentService from '../../services/CommentService';

/**
 * @typedef {import('../../models/CommentModel').default} CommentModel
 */

/**
 *
 * @param {Object} props
 * @param {CommentModel} props.comment
 * @returns {JSX.Element}
 */

const Comment = ({ comment }) => {
  const { t } = useTranslation();
  const ldClient = useLDClient();
  const [attachmentImage, setAttachmentImage] = useState(null);

  const handleError = useErrorHook();

  const handleThumbClick = () => {
    host.launchBrowser(comment.linkToComment);
  };

  useEffect(() => {
    if (comment?.attachmentUrl) {
      setAttachmentImage(comment.attachmentUrl);
    }
  }, [comment]);

  return (
    <CommentWrapper>
      <CommentHeader>
        <div>
          <Title>{comment.author}</Title>
          <Caption>{moment(comment.createdAt).format('LLL')}</Caption>
        </div>
        <ResolveLabel>
          <Checkbox
            data-testid="comment-resolve-checkbox"
            edge="end"
            color="default"
            checked={comment.isResolved}
            onChange={(event) => {
              ldClient?.track(launchDarklyMetrics.RESOLVE_COMMENT);
              adpInstance.trackComponentEvent(componentEvents.RESOLVE_COMMENT);
              return commentService().resolveComment(comment, event.target.checked).catch(handleError);
            }}
            size="small"
          />
          <ResolveLabelText>{comment.isResolved ? t('comment-resolved') : t('comment-resolve')}</ResolveLabelText>
        </ResolveLabel>
      </CommentHeader>
      <ModelThumbnailWrapper>
        <ModelThumbnail image={attachmentImage} handleClick={handleThumbClick} isCommentThumbnail />
      </ModelThumbnailWrapper>
      <CommentInfo>
        <CommentText noWrap={false}>{comment.text}</CommentText>
        <ReplyList comment={comment} />
      </CommentInfo>
    </CommentWrapper>
  );
};

export default Comment;
