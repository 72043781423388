import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { noAssignedImages } from '../../../img/missing';
import host from '../../../services/HostWrapper';
import Info from '../../Info';
import ActionItem from '../../../models/ActionItem';

const UserNotAssigned = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleNotAssignedInfoClick = () => {
    host.launchBrowser(
      'https://knowledge.autodesk.com/customer-service/account-management/users-software/classic-user-management'
    );
  };

  return (
    <Info
      img={noAssignedImages[theme.name]}
      title={t('not-assigned-access-title')}
      msg={t('not-assigned-access-info')}
      links={[
        new ActionItem(t('learn-shared-views'), host.launchHelp),
        new ActionItem(t('not-assigned-access-info-admin'), handleNotAssignedInfoClick),
      ]}
    />
  );
};

export default UserNotAssigned;
