import envConfig from 'environmentConfig';
import config from 'configs/envs/base.config.json';
import axiosInstance from '../services/axios';

export const getOxygenAnalyticsId = () => {
  return axiosInstance
    .get(`${envConfig.get().developer.url}${config.developer.analyticsId.replace('{id}', '@me')}`)
    .then((response) => {
      return response.data.analyticsId;
    });
};
