export const getExpiryDays = (expiration) => {
  const expires = new Date(expiration);
  if (expires.toDateString() === 'Invalid Date') {
    return undefined;
  }

  const now = new Date();
  const todayUTCTime = now.getTime();
  const timeDiff = Math.abs(expires.getTime() - todayUTCTime);
  const diffDays = Math.round(timeDiff / (1000 * 3600 * 24));
  return diffDays;
};
