import { useNavigate } from 'react-router-dom';
import { update } from '../utils/updateObject';
import useShallowStore from './useShallowStore';
import useErrorHook from './useErrorHook';
import useCurrentShare from './useCurrentShare';
import commentService from '../services/CommentService';

function useCommentsUpdate() {
  const navigate = useNavigate();
  const { shares, setShares, setIsLoading, setIsErrorLoading } = useShallowStore((state) => ({
    shares: state.shares,
    setShares: state.setShares,
    setIsLoading: state.setIsLoading,
    setIsErrorLoading: state.setIsErrorLoading,
  }));
  const handleError = useErrorHook();

  const removeAllLoaders = () => {
    setIsErrorLoading(false);
    setIsLoading(false);
  };
  const currentShare = useCurrentShare();
  const updateComments = (share = currentShare) => {
    navigate(`/app/shares/${share.viewerId}`);
    setIsLoading(true);

    commentService()
      .getShareComments(share.viewerId)
      .then((comments) => {
        const updatedShares = shares.map((elm) => {
          if (elm.viewerId === share.viewerId) {
            return update(elm, { numComments: comments.length });
          }

          return elm;
        });
        setShares(updatedShares);
      })
      .catch(handleError)
      .then(removeAllLoaders);
  };
  return updateComments;
}

export default useCommentsUpdate;
