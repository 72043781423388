// @ts-ignore
import envConfig from 'environmentConfig';

/**
 * @typedef {import('../types.js').HostObject} HostObject
 */
/**
 * @typedef {import('../types.js').HostWrapperMocked} HostWrapperMocked
 */

/**
 * @type {HostWrapperMocked}
 */
class HostWrapperMock {
  static token = '';

  static setToken(token) {
    this.token = token;
    if (this.onLoginStatusChange) {
      this.onLoginStatusChange();
    }
  }

  /**
   * @type {null | (() => void)}
   */
  static onLoginStatusChange = null;

  /**
   * @type {HostObject}
   */
  host;

  constructor() {
    this.getOAuth2Token = this.getOAuth2Token.bind(this);
    this.getIsLoggedIn = this.getIsLoggedIn.bind(this);
    this.getIsEntitled = this.getIsEntitled.bind(this);
    this.signIn = this.signIn.bind(this);
    this.startCollaboration = this.startCollaboration.bind(this);
    this.launchBrowser = this.launchBrowser.bind(this);
    this.launchHelp = this.launchHelp.bind(this);
    this.uploadCompleted = this.uploadCompleted.bind(this);
    this.updateUploadProgress = this.updateUploadProgress.bind(this);
    this.setRefreshCallback = this.setRefreshCallback.bind(this);
    this.setLoginStatusChangedCallback = this.setLoginStatusChangedCallback.bind(this);
    this.notifyUploadCompleted = this.notifyUploadCompleted.bind(this);
  }

  updateUploadProgress(progressUploadId, loaded, total) {
    window.parent.postMessage(
      {
        type: 'updateUploadProgress',
        data: {
          progressUploadId,
          loaded,
          total,
        },
      },
      envConfig.get().webHostApp.url
    );
  }

  /**
   * @deprecated Use {@link notifyUploadCompleted} instead.
   */
  uploadCompleted(uploadId, status, errorCode, errorMessage, viewerUrl) {
    window.parent.postMessage(
      {
        type: 'uploadCompleted',
        data: {
          uploadId,
          status,
          errorCode,
          viewerLink: viewerUrl,
        },
      },
      envConfig.get().webHostApp.url
    );
  }

  notifyUploadCompleted(
    uploadId,
    status,
    errorCode,
    errorMessage,
    viewerLink,
    shortViewerLink,
    viewableId,
    additionalMetadataJson
  ) {
    window.parent.postMessage(
      {
        type: 'notifyUploadCompleted',
        data: {
          uploadId,
          status,
          errorCode,
          errorMessage,
          viewerLink,
          shortViewerLink,
          viewableId,
          additionalMetadataJson,
        },
      },
      envConfig.get().webHostApp.url
    );
  }

  getOAuth2Token() {
    if (!HostWrapperMock.token) {
      return Promise.reject(HostWrapperMock.token);
    }
    return Promise.resolve(HostWrapperMock.token);
  }

  getIsLoggedIn() {
    return Promise.resolve(HostWrapperMock.token);
  }

  getIsEntitled() {
    return Promise.resolve(2);
  }

  signIn() {
    if (!HostWrapperMock.token) {
      window.parent.postMessage('login', envConfig.get().webHostApp.url);
      return Promise.reject(HostWrapperMock.token);
    }
    return Promise.resolve(HostWrapperMock.token);
  }

  startCollaboration() {
    return /** @type {Promise<void>} */ (
      new Promise((resolve) => {
        window.parent.postMessage(
          {
            type: 'startCollaborationCommand',
          },
          envConfig.get().webHostApp.url
        );
        const uploadInput = document.getElementById('uploadInput');
        uploadInput.click();
        return resolve();
      })
    );
  }

  launchBrowser(url) {
    window.open(url, '_blank');
  }

  launchHelp() {
    this.launchBrowser(
      'http://help.autodesk.com/view/INVNTOR/2017/ENU/?guid=GUID-81763857-BE98-4ED0-B63E-D3A5B50853E2'
    );
  }

  setRefreshCallback(callback) {
    if (this.host && Object.prototype.hasOwnProperty.call(this.host, 'setRefreshCallback')) {
      this.host.setRefreshCallback(callback);
    }
  }

  setLoginStatusChangedCallback(callback) {
    HostWrapperMock.onLoginStatusChange = callback;
  }
}

export default HostWrapperMock;
