import lightNoAssigned from './light-no-assigned.png';
import lightNoComments from './light-no-comments.png';
import lightNoSearchResult from './light-no-search-result.png';
import lightNoShares from './light-no-shares.png';
import lightNoSignedIn from './light-no-signed-in.png';
import lightNoSubscribed from './light-no-subscribed.png';

import darkNoAssigned from './dark-no-assigned.png';
import darkNoComments from './dark-no-comments.png';
import darkNoSearchResult from './dark-no-search-result.png';
import darkNoShares from './dark-no-shares.png';
import darkNoSignedIn from './dark-no-signed-in.png';
import darkNoSubscribed from './dark-no-subscribed.png';

import darkBlueNoAssigned from './dark-blue-no-assigned.png';
import darkBlueNoComments from './dark-blue-no-comments.png';
import darkBlueNoSearchResult from './dark-blue-no-search-result.png';
import darkBlueNoShares from './dark-blue-no-shares.png';
import darkBlueNoSignedIn from './dark-blue-no-signed-in.png';
import darkBlueNoSubscribed from './dark-blue-no-subscribed.png';

import { themes } from '../../constants/hostConfig';

export const noAssignedImages = {
  [themes.LIGHT]: lightNoAssigned,
  [themes.DARK]: darkNoAssigned,
  [themes.DARK_BLUE]: darkBlueNoAssigned,
};

export const noCommentsImages = {
  [themes.LIGHT]: lightNoComments,
  [themes.DARK]: darkNoComments,
  [themes.DARK_BLUE]: darkBlueNoComments,
};

export const noSearchResultImages = {
  [themes.LIGHT]: lightNoSearchResult,
  [themes.DARK]: darkNoSearchResult,
  [themes.DARK_BLUE]: darkBlueNoSearchResult,
};

export const noSharesImages = {
  [themes.LIGHT]: lightNoShares,
  [themes.DARK]: darkNoShares,
  [themes.DARK_BLUE]: darkBlueNoShares,
};

export const noSignedInImages = {
  [themes.LIGHT]: lightNoSignedIn,
  [themes.DARK]: darkNoSignedIn,
  [themes.DARK_BLUE]: darkBlueNoSignedIn,
};

export const noSubscribedImages = {
  [themes.LIGHT]: lightNoSubscribed,
  [themes.DARK]: darkNoSubscribed,
  [themes.DARK_BLUE]: darkBlueNoSubscribed,
};
