const useUIStore = (set) => ({
  searchText: '',
  matchFound: true,
  showResolvedComments: false,
  isLoading: false,
  hasFetchedShares: false,
  setSearchText: (searchText) => set({ searchText }),
  setMatchFound: (matchFound) => set({ matchFound }),
  setShowResolvedComments: (showResolvedComments) => set({ showResolvedComments }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setHasFetchedShares: (hasFetchedShares) => set({ hasFetchedShares }),
});

export default useUIStore;
