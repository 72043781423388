import CommentModel from '../models/CommentModel';
import ReplyModel from '../models/ReplyModel';
import defaultAxiosInstance from './axios';
import apiClientHelper from '../clients/helper';
import { commentsStatuses } from '../constants/shares';
import store from '../stores';

import { update } from '../utils/updateObject';

class CommentService {
  constructor() {
    if (CommentService.instance) {
      return CommentService.instance;
    }
    this.apiClient = apiClientHelper.selectCommentApiClient(defaultAxiosInstance);
    CommentService.instance = this;
  }

  static instance;

  createComment(data) {
    // Create reply objects if any
    const replies = [];
    if (data.comment) {
      data.comment.forEach((obj) => {
        if (obj.name && obj.body && obj.published) {
          replies.push(new ReplyModel({ author: obj.name, text: obj.body, createdAt: obj.published }));
        }
      });
    }

    return new CommentModel({
      author: data.name,
      text: data.body,
      createdAt: data.published,
      commentId: data.id,
      linkToComment: data.linkToComment,
      isResolved: data.status !== commentsStatuses.OPEN,
      attachmentUrl: data.attachmentUrl,
      replies,
    });
  }

  getShareComments(designShareId) {
    return this.apiClient.getComments(designShareId).then((commentsResponse) => {
      const { setComments } = store.getState();
      const comments = [];
      if (!commentsResponse || commentsResponse.length === 0) {
        setComments(comments);
        return comments;
      }

      // Create comment objects for each comments
      commentsResponse.forEach((value) => {
        const comment = this.createComment(value);
        comments.push(comment);
      });
      setComments(comments);

      return comments;
    });
  }

  resolveComment(comment, isResolved) {
    const { comments, setComments } = store.getState();
    let data = {};
    const { commentId } = comment;

    if (isResolved === true) data = { status: commentsStatuses.RESOLVED };
    else data = { status: commentsStatuses.OPEN };

    return this.apiClient.resolve(commentId, data).then((res) => {
      setComments(
        comments.map((commentItem) => {
          if (commentItem.commentId === comment.commentId) {
            return update(commentItem, { isResolved });
          }

          return commentItem;
        })
      );
      return res;
    });
  }

  postReply(comment, reply, user) {
    const { commentId } = comment;
    const { comments, setComments } = store.getState();
    return this.apiClient.reply(commentId, reply, user).then((response) => {
      const newReply = new ReplyModel({
        author: response.data.name,
        text: response.data.body,
        createdAt: response.data.published,
      });
      setComments(
        comments.map((commentItem) => {
          if (commentItem.commentId === comment.commentId) {
            return update(commentItem, { replies: [...comment.replies, newReply] });
          }

          return commentItem;
        })
      );
      return newReply;
    });
  }
}

const commentService = () => new CommentService();

export default commentService;
