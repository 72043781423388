import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import newRelicHelper from '../utils/newRelic/helper';

const useFlagsUpdate = () => {
  const flags = useFlags();
  useEffect(() => {
    if (Object.keys(flags).length === 0) return;
    newRelicHelper.trackFeatureFlags(flags);
  }, [flags]);
};

export default useFlagsUpdate;
