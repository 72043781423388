import { useLDClient } from 'launchdarkly-react-client-sdk';
import useCurrentShare from './useCurrentShare';
import useSharesUpdate from './useSharesUpdate';
import useCommentsUpdate from './useCommentsUpdate';
import { componentEvents, launchDarklyMetrics } from '../constants/analyticsConfig';
import adpInstance from '../utils/adp';

function useStoreUpdate() {
  const updateShares = useSharesUpdate();
  const updateComments = useCommentsUpdate();
  const currentShare = useCurrentShare();
  const ldClient = useLDClient();

  const generalUpdate = () => {
    if (currentShare) {
      ldClient.track(launchDarklyMetrics.UPDATE_COMMENTS);
      adpInstance.trackComponentEvent(componentEvents.UPDATE_COMMENTS);
      updateComments();
    } else {
      ldClient.track(launchDarklyMetrics.UPDATE_SHARES);
      adpInstance.trackComponentEvent(componentEvents.UPDATE_SHARES);
      updateShares();
    }
  };
  return { generalUpdate };
}

export default useStoreUpdate;
