import React from 'react';

import moment from 'moment';
import { Caption, Title } from '../../Text';
import { ReplyWrapper, ReplyText } from './styled';

/**
 * @typedef {import('../../../models/ReplyModel').default} ReplyModel
 */

/**
 * @param {Object} props
 * @param {ReplyModel} props.reply
 * @returns {JSX.Element}
 */
const Reply = ({ reply }) => (
  <ReplyWrapper>
    <Title>{reply.author}</Title>
    <Caption>{moment(reply.createdAt).format('LLL')}</Caption>
    <ReplyText data-testid="comment-reply-text" noWrap={false}>
      {reply.text}
    </ReplyText>
  </ReplyWrapper>
);
export default Reply;
