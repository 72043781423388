import { createdAt, title, expiryDate } from '../utils/sort';

const useSharesStore = (set, get) => ({
  shares: [],
  shareToDelete: {},
  sortBy: createdAt,
  setShares(shares) {
    set({ shares });
  },
  setShareToDelete(share) {
    set(() => ({ shareToDelete: share }));
  },
  setSortBy(sortBy) {
    set(() => ({ sortBy }));
  },
  changeSortBy(sortBy) {
    const { shares, setShares, setSortBy } = get();
    const sortedShares = [...shares];
    switch (sortBy) {
      case createdAt:
        sortedShares.sort((a, b) => {
          if (a.createdAt > b.createdAt) return -1;
          if (a.createdAt < b.createdAt) return 1;
          return 0;
        });
        break;
      case title:
        sortedShares.sort((a, b) => {
          if (a.title > b.title) return 1;
          if (a.title < b.title) return -1;
          return 0;
        });
        break;
      case expiryDate:
        sortedShares.sort((a, b) => {
          if (a.expires > b.expires) return 1;
          if (a.expires < b.expires) return -1;
          return 0;
        });
        break;
      default:
        // Nothing
        return;
    }
    setSortBy(sortBy);
    setShares(sortedShares);
  },
});

export default useSharesStore;
