/**
 * Info is used for informations or error panels. It renders:
 * - Img
 * - Title or Link title
 * - Msg
 * - Buttons
 * - Links
 */
import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import ActionItem from '../../models/ActionItem';
import Loader from '../Loader';
import { Title } from '../Text';
import { InfoActions, InfoBodyText, InfoImage, InfoLink, InfoLinkGroupWrapper, InfoWrapper } from './styled';

const Info = ({ img, title, handleTitleClick, msg, buttons, links, refreshing }) => {
  const infoTitle = handleTitleClick ? (
    <Link href="#" onClick={handleTitleClick}>
      {title}
    </Link>
  ) : (
    title
  );

  const infoButtons = buttons.map((button, index) => (
    <Button key={button.label} onClick={button.onClick} variant={index === 0 ? 'contained' : 'outlined'}>
      {button.label}
    </Button>
  ));
  // Reverse so the first button in the list is the primary button
  infoButtons.reverse();

  const infoLinks = links.map((link) => (
    <InfoLink key={link.label}>
      <Link href="#" onClick={link.onClick} color="textSecondary">
        {link.label}
      </Link>
    </InfoLink>
  ));

  return (
    <InfoWrapper>
      {img && <InfoImage src={img} alt="img" />}
      <Title noWrap={false}>{infoTitle}</Title>
      {msg && <InfoBodyText noWrap={false}>{msg}</InfoBodyText>}
      <InfoActions>{refreshing ? <Loader size="30" /> : infoButtons}</InfoActions>

      <InfoLinkGroupWrapper>{infoLinks}</InfoLinkGroupWrapper>
    </InfoWrapper>
  );
};

Info.defaultProps = {
  img: undefined,
  handleTitleClick: undefined,
  msg: undefined,
  buttons: [],
  links: [],
  refreshing: false,
};

Info.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string.isRequired,
  handleTitleClick: PropTypes.func,
  msg: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.instanceOf(ActionItem)),
  links: PropTypes.arrayOf(PropTypes.instanceOf(ActionItem)),
  refreshing: PropTypes.bool,
};

export default Info;
