import { adaptV4Theme } from '@mui/material/styles';

import HigLightTheme from '@adsk/material-ui-hig-themes/build/themes/lightGrayHighDensityTheme.json';
import HigDarkTheme from '@adsk/material-ui-hig-themes/build/themes/darkGrayHighDensityTheme.json';
import HigDarkBlueTheme from '@adsk/material-ui-hig-themes/build/themes/darkBlueHighDensityTheme.json';

import { themes } from '../constants/hostConfig';
import { getScheme } from './urlUtil';

export const LightTheme = adaptV4Theme({ ...HigLightTheme, name: themes.LIGHT });
export const DarkTheme = adaptV4Theme({ ...HigDarkTheme, name: themes.DARK });
export const DarkBlueTheme = adaptV4Theme({ ...HigDarkBlueTheme, name: themes.DARK_BLUE });

export const getTheme = () => {
  const theme = getScheme();

  return (
    { [themes.LIGHT]: LightTheme, [themes.DARK]: DarkTheme, [themes.DARK_BLUE]: DarkBlueTheme }[theme] || LightTheme
  );
};
