/**
 * Search is used to perform search in the shares. It renders:
 * - Search icon
 * - Input
 * - If searching: Exit icon
 */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import debounce from 'lodash.debounce';
import useShallowStore from '../../../hooks/useShallowStore';
import { SearchInput, SearchWrapper } from './styled';

const Search = () => {
  const { t } = useTranslation();
  const { searchText, setSearchText } = useShallowStore((state) => ({
    searchText: state.searchText,
    setSearchText: state.setSearchText,
  }));
  const [inputValue, setInputValue] = useState(searchText);
  // Stop searching and clear input
  const exitSearch = () => {
    setSearchText('');
    setInputValue('');
  };

  const handleOnKeyDown = (e) => {
    if (searchText && e.key === 'Escape') {
      exitSearch();
    }
  };

  const debouncedSetSearchText = debounce((value) => {
    setSearchText(value);
  }, 100);
  const handleChange = useCallback(
    (event) => {
      const { value } = event.target;
      debouncedSetSearchText(value);
      setInputValue(value);
    },
    [debouncedSetSearchText]
  );

  return (
    <SearchWrapper>
      <SearchIcon />
      <SearchInput
        value={inputValue}
        onChange={handleChange}
        placeholder={t('search-input-placeholder')}
        onKeyDown={handleOnKeyDown}
      />
      {searchText && (
        <IconButton onClick={exitSearch}>
          <CancelIcon />
        </IconButton>
      )}
    </SearchWrapper>
  );
};

export default Search;
