export const uploadStatuses = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  UPLOAD_CANCELED: 'UPLOAD_CANCELED',
};

export const statusCodes = {
  UPLOAD_SUCCEED: 0,
  CREATE_UPLOAD_SESSION_FAILED: 1,
  GENERATE_SIGNED_URL_FAILED: 2,
  RETRIEVE_LOCAL_FILE_FAILED: 3,
  S3_DIRECT_UPLOAD_FAILED: 4,
  COMPLETE_OSS_UPLOAD_FAILED: 5,
  CREATE_SHARE_FAILED: 6,
  UPLOAD_CANCELED: 7,
};

export const errorMessages = {
  [statusCodes.CREATE_UPLOAD_SESSION_FAILED]: 'REST API call to create upload session failed',
  [statusCodes.GENERATE_SIGNED_URL_FAILED]: 'REST API call to Connect to generate signed URL failed',
  [statusCodes.RETRIEVE_LOCAL_FILE_FAILED]: 'Failed to download the file using fileUrl provided by Desktop App',
  [statusCodes.S3_DIRECT_UPLOAD_FAILED]: 'Failed to upload the file to S3',
  [statusCodes.COMPLETE_OSS_UPLOAD_FAILED]: 'REST API call to Connect to complete upload failed',
  [statusCodes.CREATE_SHARE_FAILED]: 'REST API call to create share failed',
};
