export const sharesSortOptions = {
  Created: 'filter-date-uploaded',
  DesignTitle: 'filter-title',
  ExpirationDate: 'filter-expiration-date',
};

export const sharesStatuses = {
  SUCCESS: 'Success',
  TRANSLATING: 'Translating',
  FAILED: 'Failed',
};

export const commentsStatuses = {
  OPEN: 'open',
  RESOLVED: 'resolved',
};
