import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { BodyText, Caption } from '../Text';

/**
 * @template {{}} Props
 * @typedef {import('@emotion/styled').StyledComponent<Props>} StyledComponent<Props>
 */

/**
 * @type {StyledComponent<'div'>}
 */
export const CommentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(0.5),
  padding: theme.spacing(0, 1),
  backgroundColor: theme.palette.background.default,
}));

/**
 * @type {StyledComponent<'div'>}
 */
export const ModelThumbnailWrapper = styled('div')({
  height: '200px',
});

/**
 * @type {StyledComponent<import('@mui/material').ButtonProps>}
 */
export const CancelButton = styled(Button)(({ theme }) => ({ marginRight: theme.spacing(0.5) }));

/**
 * @type {StyledComponent<import('@mui/material/styles/createTypography').Typography>}
 */
export const CharLeft = styled(Caption)(({ theme }) => ({ marginTop: theme.spacing(0.5) }));

/**
 * @type {StyledComponent<'div'>}
 */
export const ReplyActions = styled('div')(({ theme }) => ({
  alignSelf: 'flex-end',
  marginTop: theme.spacing(1),
}));

/**
 * @type {StyledComponent<'div'>}
 */
export const AddReply = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1),
}));

/**
 * @type {StyledComponent<import('@mui/material/styles/createTypography').Typography>}
 */
export const CommentText = styled(BodyText)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  overflowWrap: 'break-word',
}));

/**
 * @type {StyledComponent<'div'>}
 */
export const CommentHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: theme.spacing(0.5, 0),
}));

/**
 * @type {StyledComponent<'div'>}
 */
export const CommentInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(1, 0),
}));

/**
 * @type {StyledComponent<import('@mui/material').InputLabelProps>}
 */
export const ResolveLabel = styled(InputLabel)({
  display: 'flex',
  alignItems: 'center',
});

/**
 * @type {StyledComponent<'span'>}
 */
export const ResolveLabelText = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
}));

/**
 * @type {StyledComponent<import('@mui/material').OutlinedInputProps>}
 */
export const CommentReplyInput = styled(OutlinedInput)(({ theme }) => ({
  height: 'auto',
  padding: theme.spacing(0),
  transitionProperty: 'border',
  '& textarea': {
    padding: theme.spacing(1),
  },
  '&&.MuiOutlinedInput-root': {
    boxShadow: 'none',
    '&:hover:not(.Mui-focused)': {
      border: `1px solid ${theme.palette.grey[400]}`,
    },
  },
  '&&.Mui-focused': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));
