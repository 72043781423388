import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import '../../style/main.scss';
import { Error4xx, Error5xx, ErrorUnknown, UserNotEntitled, UserNotAssigned } from './Error';
import SharesView from '../SharesView';
import CommentsView from '../CommentsView';
import { App, SignIn } from './index';
import useRouteChange from '../../hooks/useRouteChange';
import useSharesUpdate from '../../hooks/useSharesUpdate';
import host from '../../services/HostWrapper';

const Router = () => {
  const stopPropagation = (e) => {
    e.stopImmediatePropagation();
  };
  useEffect(() => {
    window.addEventListener('beforeunload', stopPropagation);

    return () => {
      window.removeEventListener('beforeunload', stopPropagation);
    };
  }, []);
  const updateShares = useSharesUpdate();
  useRouteChange();
  host.setRefreshCallback(updateShares);
  return (
    <Routes>
      <Route index element={<SignIn />} />
      <Route path="usernotassigned" element={<UserNotAssigned />} />
      <Route path="usernotentitled" element={<UserNotEntitled />} />
      <Route path="unknownerror" element={<ErrorUnknown />} />
      <Route path="app" element={<App />}>
        <Route index element={<SharesView />} />
        <Route path="shares/:shareId" element={<CommentsView />} />
        <Route path="clienterror" element={<Error4xx />} />
        <Route path="servererror" element={<Error5xx />} />
      </Route>
    </Routes>
  );
};

export default Router;
