import axios from 'axios';
import { uploadFileFromPanel } from '../constants/hostConfig';
import { errorMessages, statusCodes, uploadStatuses } from '../constants/uploadFileStatuses';
import UploadService from '../services/UploadService';
import host from '../services/HostWrapper';
import { getUploadFileFromPanel } from './urlUtil';

function setStartUploadFunction({ onUpload = () => {} }) {
  if (getUploadFileFromPanel() === uploadFileFromPanel.ON && !window.startUpload) {
    const uploads = {};
    window.startUpload = async (uploadId, fileName, displayName, fileSize, fileUrl) => {
      try {
        const uploadProgressConfig = {
          onUploadProgress(progressUploadId, progressEvent) {
            host.updateUploadProgress(progressUploadId, progressEvent.loaded, progressEvent.total);
          },
        };
        const uploadInstance = new UploadService();
        uploads[uploadId] = uploadInstance;
        const uploadResponse = await uploadInstance.createUploadSession(
          uploadId,
          fileName,
          displayName,
          fileUrl,
          uploadProgressConfig
        );
        const additionalMetadataJson = JSON.stringify({ thumbnailUrl: uploadResponse.ThumbnailUrl });

        host.notifyUploadCompleted(
          uploadId,
          uploadStatuses.SUCCESS,
          statusCodes.UPLOAD_SUCCEED,
          null,
          uploadResponse.ViewerUrl,
          uploadResponse.ViewerShortenedUrl,
          uploadResponse.ViewerId,
          additionalMetadataJson
        );
        onUpload();
      } catch (error) {
        if (axios.isCancel(error.cause)) {
          host.notifyUploadCompleted(uploadId, uploadStatuses.UPLOAD_CANCELED, statusCodes.UPLOAD_CANCELED);
        } else {
          const errorCode = error.message;
          host.notifyUploadCompleted(uploadId, uploadStatuses.ERROR, +errorCode, errorMessages[errorCode]);
        }
      } finally {
        delete uploads[uploadId];
      }
    };
    window.cancelUpload = (uploadId) => {
      if (uploads[uploadId]) {
        uploads[uploadId].cancelUpload();
      }
    };
  }
}

export { setStartUploadFunction };
