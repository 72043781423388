import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { sharesSortOptions } from '../../constants/shares';
import useShallowStore from '../../hooks/useShallowStore';
import { SortByWrapper, MenuItemText, SortBySelect } from './styled';
import adpInstance from '../../utils/adp';
import { componentEvents, launchDarklyMetrics } from '../../constants/analyticsConfig';

/**
 *
 * @returns {JSX.Element}
 */
const SharesViewHeader = () => {
  const { searchText, shares, sortBy, changeSortBy, matchFound } = useShallowStore((state) => ({
    searchText: state.searchText,
    shares: state.shares,
    sortBy: state.sortBy,
    changeSortBy: state.changeSortBy,
    matchFound: state.matchFound,
  }));

  const { t } = useTranslation();
  const ldClient = useLDClient();

  const sortByText = `${t('filter-prefix')}${t(sharesSortOptions[sortBy])}`;
  if (shares?.length <= 0 || (searchText && !matchFound)) {
    // User has no shares or there are no search results
    return <></>;
  }
  // User has valid shares or search results, return sort options
  return (
    <SortByWrapper>
      <SortBySelect
        value={sortBy}
        title={sortByText}
        onChange={(event) => {
          ldClient?.track(launchDarklyMetrics.SORT_SHARES);
          adpInstance.trackComponentEvent(componentEvents.SORT_SHARES);
          changeSortBy(event.target.value);
        }}
        renderValue={() => sortByText}
        variant="outlined"
        MenuProps={{
          marginThreshold: 6,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
        data-testid="sort-by-dropdown-button"
      >
        {Object.keys(sharesSortOptions).map((sortByValue) => (
          <MenuItem data-testid="sort-by-dropdown-menu-item" key={sortByValue} value={sortByValue} dense>
            <MenuItemText>{t(sharesSortOptions[sortByValue])}</MenuItemText>
          </MenuItem>
        ))}
      </SortBySelect>
    </SortByWrapper>
  );
};

export default SharesViewHeader;
