// Share object
class ShareModel {
  constructor({
    title,
    createdAt,
    a360link,
    status,
    lastUpdated,
    expires,
    thumbnail,
    numComments = 0,
    viewerId,
    fileExtension,
  }) {
    this.title = title;
    this.createdAt = createdAt;
    this.a360link = a360link;
    this.status = status;
    this.lastUpdated = lastUpdated;
    this.expires = expires;
    this.thumbnail = thumbnail;
    this.numComments = numComments;
    this.viewerId = viewerId;
    this.fileExtension = fileExtension;
  }
}

export default ShareModel;
