// @ts-ignore
import envConfig from 'environmentConfig';
import HostWrapperMock from '../services/HostWrapperMock';

function isOpenInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    /**
     * If there is an error it is safer to assume
     * that we are not in IFrame due to Desktop App ingrations
     */
    return false;
  }
}

function isHostedOnLocal() {
  return window.location.href.includes('local');
}

function isHostedOnDev() {
  return window.location.href.includes('-dev');
}

function isWebHostAppAllowed() {
  const isLocal = isHostedOnLocal();
  const isDev = isHostedOnDev();
  return isOpenInIframe() && (isLocal || isDev);
}

function notifyHostAppAndSetAccessToken(hostUrl) {
  window.parent.postMessage('getToken', hostUrl);
  window.addEventListener('message', (event) => {
    if (event.origin.startsWith(hostUrl)) {
      const { accessToken } = JSON.parse(event.data);

      HostWrapperMock.setToken(accessToken);
    }
  });
}

function setUpWebHostAppIfAvailable() {
  const webHostAppConfig = envConfig.get().webHostApp;
  // Config should not exist in STG and PROD;
  if (!webHostAppConfig) {
    return;
  }
  if (isWebHostAppAllowed()) {
    notifyHostAppAndSetAccessToken(webHostAppConfig.url);
  }
}

export { setUpWebHostAppIfAvailable, isWebHostAppAllowed };
