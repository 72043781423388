/**
 * @typedef {import('../types').HostWrapper} HostWrapperClass
 * @typedef {import('../types').HostObject} HostObject
 */

import HostWrapperMock from './HostWrapperMock';
import { isWebHostAppAllowed } from '../utils/webHostAppUtils';
import { getUploadFileFromPanel } from '../utils/urlUtil';
import { uploadFileFromPanel } from '../constants/hostConfig';

/** @type {Window & { HostObject?: HostObject }} */
const windowWithHostObject = window;

/**
 * @type {HostWrapperClass}
 */
class HostWrapper {
  constructor() {
    this.getOAuth2Token = this.getOAuth2Token.bind(this);
    this.getIsLoggedIn = this.getIsLoggedIn.bind(this);
    this.getIsEntitled = this.getIsEntitled.bind(this);
    this.signIn = this.signIn.bind(this);
    this.startCollaboration = this.startCollaboration.bind(this);
    this.startCollaborationCommand = this.startCollaborationCommand.bind(this);
    this.updateUploadProgress = this.updateUploadProgress.bind(this);
    this.notifyUploadCompleted = this.notifyUploadCompleted.bind(this);
    this.uploadCompleted = this.uploadCompleted.bind(this);
    this.launchBrowser = this.launchBrowser.bind(this);
    this.launchHelp = this.launchHelp.bind(this);
    this.setRefreshCallback = this.setRefreshCallback.bind(this);
    this.setLoginStatusChangedCallback = this.setLoginStatusChangedCallback.bind(this);
    this.print = this.print.bind(this);

    this._isHostObjectMethodDefined = async (methodName) => {
      try {
        // Perform legacy check first (works with all browsers except WebView2)
        const hostObject = windowWithHostObject.HostObject;
        if (!hostObject) {
          return false;
        }

        const isMethodDefined = Object.prototype.hasOwnProperty.call(hostObject, methodName);
        if (isMethodDefined) {
          return true;
        }

        // If method is not defined till this point - probably we are using WebView2.
        // Additional WebView2 specific checks, as WebView2 wraps host object in async JS Proxy.
        // https://learn.microsoft.com/en-us/microsoft-edge/webview2/reference/javascript/hostobjectasyncproxy
        const method = hostObject[methodName];
        const isPromise = typeof method.then === 'function';
        if (!isPromise) {
          return typeof method === 'function';
        }

        const resolvedMethod = await method;
        return typeof resolvedMethod === 'function';
      } catch (e) {
        // If there is an error - this means that the method is not defined or not accessible
        return false;
      }
    };

    // Is used to invoke void host object method and correclty handle sync and async cases
    // We need to handle this as we don't know which versions of HostObjects (sync or async)
    // desktop apps can expose.
    this._invokeVoidHostObjectMethod = (invocation, resolve, reject) => {
      try {
        const result = invocation();

        if (result && typeof result.then === 'function') {
          result.then(resolve, reject);
        } else if (resolve) {
          resolve();
        }
      } catch (e) {
        reject(e);
      }
    };
  }

  // Those can now be used with the Promise syntax instead of with Callbacks
  getOAuth2Token() {
    return new Promise((resolve, reject) => {
      this._isHostObjectMethodDefined('getOAuth2Token').then((isMethodDefined) => {
        if (isMethodDefined) {
          this.print('getOAuth2Token method called');
          const invocation = () => windowWithHostObject.HostObject.getOAuth2Token(resolve);
          this._invokeVoidHostObjectMethod(invocation, null, reject); // Is resolved in the callback
        } else {
          reject();
        }
      });
    });
  }

  getIsLoggedIn() {
    return new Promise((resolve, reject) => {
      this._isHostObjectMethodDefined('getIsLoggedIn').then((isMethodDefined) => {
        if (isMethodDefined) {
          this.print('getIsLoggedIn method called');
          const invocation = () => windowWithHostObject.HostObject.getIsLoggedIn(resolve);
          this._invokeVoidHostObjectMethod(invocation, null, reject); // Is resolved in the callback
        } else {
          reject();
        }
      });
    });
  }

  getIsEntitled() {
    return new Promise((resolve, reject) => {
      this._isHostObjectMethodDefined('getIsEntitled').then((isMethodDefined) => {
        if (isMethodDefined) {
          this.print('getIsEntitled method called');
          const invocation = () => windowWithHostObject.HostObject.getIsEntitled(resolve);
          this._invokeVoidHostObjectMethod(invocation, null, reject); // Is resolved in the callback
        } else {
          reject();
        }
      });
    });
  }

  signIn() {
    return new Promise((resolve, reject) => {
      this._isHostObjectMethodDefined('signIn').then((isMethodDefined) => {
        if (isMethodDefined) {
          this.print('signIn method called');
          const invocation = () => windowWithHostObject.HostObject.signIn(resolve);
          this._invokeVoidHostObjectMethod(invocation, null, reject); // Is resolved in the callback
        } else {
          reject();
        }
      });
    });
  }

  startCollaboration() {
    if (getUploadFileFromPanel() === uploadFileFromPanel.ON) {
      return this.startCollaborationCommand();
    }

    return new Promise((resolve, reject) => {
      this._isHostObjectMethodDefined('startCollaboration').then((isMethodDefined) => {
        if (isMethodDefined) {
          this.print('startCollaboration method called');
          const invocation = () => windowWithHostObject.HostObject.startCollaboration(resolve);
          this._invokeVoidHostObjectMethod(invocation, null, reject); // Is resolved in the callback
        } else {
          reject();
        }
      });
    });
  }

  notifyUploadCompleted(
    uploadId,
    status,
    errorCode,
    errorMessage,
    viewerLink,
    shortViewerLink,
    viewableId,
    additionalMetadataJson
  ) {
    return /** @type {Promise<void>} */ (
      new Promise((resolve, reject) => {
        this._isHostObjectMethodDefined('notifyUploadCompleted').then((isNotifyUploadCompletedDefined) => {
          if (isNotifyUploadCompletedDefined) {
            this.print(
              `notifyUploadCompleted method called with: uploadId: ${uploadId}, status: ${status}, errorCode: ${errorCode}, errorMessage: ${errorMessage}, viewerLink: ${viewerLink}`
            );
            const invocation = () =>
              windowWithHostObject.HostObject.notifyUploadCompleted(
                uploadId,
                status,
                errorCode,
                errorMessage,
                viewerLink,
                shortViewerLink,
                viewableId,
                additionalMetadataJson
              );

            this._invokeVoidHostObjectMethod(invocation, resolve, reject);
          } else {
            // backwards compatibility
            this.uploadCompleted(uploadId, status, errorCode, errorMessage, shortViewerLink).then(resolve, reject);
          }
        });
      })
    );
  }

  /**
   * @deprecated Use {@link notifyUploadCompleted} instead.
   */
  uploadCompleted(uploadId, status, errorCode, errorMessage, viewerLink) {
    return /** @type {Promise<void>} */ (
      new Promise((resolve, reject) => {
        this._isHostObjectMethodDefined('uploadCompleted').then((isMethodDefined) => {
          if (isMethodDefined) {
            this.print(
              `uploadCompleted method called with: uploadId: ${uploadId}, status: ${status}, errorCode: ${errorCode}, errorMessage: ${errorMessage}, viewerLink: ${viewerLink}`
            );
            const invocation = () =>
              windowWithHostObject.HostObject.uploadCompleted(uploadId, status, errorCode, errorMessage, viewerLink);
            this._invokeVoidHostObjectMethod(invocation, resolve, reject);
          } else {
            reject();
          }
        });
      })
    );
  }

  updateUploadProgress(uploadId, uploadedBytes, totalBytes) {
    return /** @type {Promise<void>} */ (
      new Promise((resolve, reject) => {
        this._isHostObjectMethodDefined('updateUploadProgress').then((isMethodDefined) => {
          if (isMethodDefined) {
            this.print(
              `updateUploadProgress method called with: uploadId: ${uploadId}, uploadedBytes: ${uploadedBytes}, totalBytes${totalBytes}`
            );
            const invocation = () =>
              windowWithHostObject.HostObject.updateUploadProgress(uploadId, uploadedBytes, totalBytes);
            this._invokeVoidHostObjectMethod(invocation, resolve, reject);
          } else {
            reject();
          }
        });
      })
    );
  }

  startCollaborationCommand() {
    return /** @type {Promise<void>} */ (
      new Promise((resolve, reject) => {
        this._isHostObjectMethodDefined('startCollaborationCommand').then((isMethodDefined) => {
          if (isMethodDefined) {
            this.print('startCollaborationCommand method called');
            const invocation = () => windowWithHostObject.HostObject.startCollaborationCommand();
            this._invokeVoidHostObjectMethod(invocation, resolve, reject);
          } else {
            reject();
          }
        });
      })
    );
  }

  launchBrowser(url) {
    return this._isHostObjectMethodDefined('launchBrowser').then((isMethodDefined) => {
      if (isMethodDefined) {
        this.print(`launchBrowser called with: url: ${url} and boolean: ${false}`);
        windowWithHostObject.HostObject.launchBrowser(url, false);
      }
    });
  }

  launchHelp() {
    return this._isHostObjectMethodDefined('launchHelp').then((isMethodDefined) => {
      if (isMethodDefined) {
        this.print('launchHelp method called');
        windowWithHostObject.HostObject.launchHelp();
      }
    });
  }

  setRefreshCallback(callback) {
    return this._isHostObjectMethodDefined('setRefreshCallback').then((isMethodDefined) => {
      if (isMethodDefined) {
        this.print('setRefreshCallback method called with callback function');
        windowWithHostObject.HostObject.setRefreshCallback(callback);
      }
    });
  }

  setLoginStatusChangedCallback(callback) {
    return this._isHostObjectMethodDefined('setLoginStatusChangedCallback').then((isMethodDefined) => {
      if (isMethodDefined) {
        this.print('setLoginStatusChangedCallback called with callback function');
        windowWithHostObject.HostObject.setLoginStatusChangedCallback(callback);
      }
    });
  }

  print(str) {
    // eslint-disable-next-line no-console
    console.debug(str);
  }
}

const host = isWebHostAppAllowed() ? new HostWrapperMock() : new HostWrapper();
Object.freeze(host); // Prevent changes to host
export default host;
