import { useNavigate } from 'react-router-dom';
import useShallowStore from './useShallowStore';
import useErrorHook from './useErrorHook';
import shareService from '../services/ShareService';

function useSharesUpdate() {
  const navigate = useNavigate();
  const { setShares, setIsLoading, setIsErrorLoading } = useShallowStore((state) => ({
    setIsLoading: state.setIsLoading,
    setIsErrorLoading: state.setIsErrorLoading,
    setShares: state.setShares,
  }));
  const handleError = useErrorHook();

  const removeAllLoaders = () => {
    setIsErrorLoading(false);
    setIsLoading(false);
  };

  const updateShares = () => {
    setIsLoading(true);
    shareService()
      .getShares()
      .then(() => {
        navigate('/app');
      })
      .catch((error) => {
        setShares([]);
        handleError(error);
      })
      .then(removeAllLoaders);
  };

  return updateShares;
}

export default useSharesUpdate;
