import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const HiddenUploadBtn = () => {
  const handleUploadThroughHostApp = (e) => {
    const { files } = e.target;
    const fileUrl = URL.createObjectURL(files[0]);
    const fileName = files[0].name;
    const fileSize = files[0].size;
    window.startUpload(uuidv4(), fileName, fileName, fileSize, fileUrl);
  };

  return (
    <input id="uploadInput" type="file" multiple style={{ display: 'none' }} onChange={handleUploadThroughHostApp} />
  );
};

export default HiddenUploadBtn;
