import create from 'zustand';
import useCommentsStore from './comments';
import useSharesStore from './shares';
import useUIStore from './ui';
import useUserStore from './user';
import useErrorStore from './error';

export default create((set, get) => {
  return {
    ...useCommentsStore(set),
    ...useSharesStore(set, get),
    ...useUIStore(set),
    ...useUserStore(set),
    ...useErrorStore(set),
  };
});
