import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';

export const CollaborateWrapper = styled('div')(({ theme }) => ({
  margin: `${theme.spacing(3)} auto 0`,
}));

export const UploadShareButton = styled(Button)({
  maxHeight: '32px',
});
