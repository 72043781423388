import envConfig from 'environmentConfig';
import baseConfig from 'configs/envs/base.config.json';
import { isWebHostAppAllowed } from '../../utils/webHostAppUtils';

export const getCreateSessionUrl = function getCreateSessionUrl() {
  if (isWebHostAppAllowed()) return `${envConfig.get().viewerApi.url}${baseConfig.developer.createSessionWebHost}`;
  return `${envConfig.get().developer.url}${baseConfig.developer.simpleShareAPIVersion}${
    baseConfig.developer.createSession
  }`;
};

export const getCreateShareUrl = function () {
  if (isWebHostAppAllowed()) return envConfig.get().viewerApi.url + baseConfig.developer.createShareWebHost;
  return envConfig.get().developer.url + baseConfig.developer.simpleShareAPIVersion + baseConfig.developer.createShare;
};
