export const pages = {
  sharedViewsPage: '/sharedviews',
  commentsPage: '/sharedviews/:id',
};

export const actions = {
  addCommentClick: 'AddCommentClick',
  extendSharedViewClick: 'ExtendSharedViewClick',
};

export const launchDarklyMetrics = {
  UPLOAD_FILE: 'UploadFile',
  DELETE_SHARE_PROMPT: 'DeleteSharePrompt',
  SHARE_DELETED: 'ShareDeleted',
  COPY_LINK: 'CopyLink',
  EXTEND_SHARE: 'ExtendShare',
  CLICK_SHARE: 'ClickShare',
  ADD_COMMENT_CLICK: 'AddCommentClick',
  OPEN_IN_BROWSER: 'OpenInBrowser',
  SORT_SHARES: 'SortShares',
  UPDATE_COMMENTS: 'UpdateComments',
  UPDATE_SHARES: 'UpdateShares',
  RESOLVE_COMMENT: 'ResolveComment',
  POST_REPLY: 'PostReply',
};

export const componentEvents = {
  UPLOAD_FILE: 'UploadFile',
  DELETE_SHARE_PROMPT: 'DeleteSharePrompt',
  SHARE_DELETED: 'ShareDeleted',
  COPY_LINK: 'CopyLink',
  EXTEND_SHARE: 'ExtendShare',
  CLICK_SHARE: 'ClickShare',
  ADD_COMMENT_CLICK: 'AddCommentClick',
  OPEN_IN_BROWSER: 'OpenInBrowser',
  SORT_SHARES: 'SortShares',
  UPDATE_COMMENTS: 'UpdateComments',
  UPDATE_SHARES: 'UpdateShares',
  RESOLVE_COMMENT: 'ResolveComment',
  POST_REPLY: 'PostReply',
};

export const events = {
  APPLICATION_VIEW: 'APPLICATION_VIEW',
  BACKGROUND_CALL: 'BACKGROUND_CALL',
  BACKGROUND_DOWNLOAD: 'BACKGROUND_DOWNLOAD',
  BACKGROUND_INSTALL: 'BACKGROUND_INSTALL',
  BACKGROUND_JOB: 'BACKGROUND_JOB',
  BACKGROUND_UPDATE: 'BACKGROUND_UPDATE',
  CLICK_COMPONENT: 'CLICK_COMPONENT',
  CLICK_DISPLAY: 'CLICK_DISPLAY',
  CLICK_OPERATION: 'CLICK_OPERATION',
  CLICK_PAGE: 'CLICK_PAGE',
  DOCUMENT_END: 'DOCUMENT_END',
  DOCUMENT_FULL: 'DOCUMENT_FULL',
  DOCUMENT_NAME: 'DOCUMENT_NAME',
  DOCUMENT_START: 'DOCUMENT_START',
  DOCUMENT_TYPE: 'DOCUMENT_TYPE',
  GESTURE_COMPONENT: 'GESTURE_COMPONENT',
  HOVER_COMPONENT: 'HOVER_COMPONENT',
  OTHER_VIEW: 'OTHER_VIEW',
  PROJECT_END: 'PROJECT_END',
  PROJECT_FULL: 'PROJECT_FULL',
  PROJECT_NAME: 'PROJECT_NAME',
  PROJECT_START: 'PROJECT_START',
  SESSION_DETAILS: 'SESSION_DETAILS',
  SESSION_END: 'SESSION_END',
  SESSION_FULL: 'SESSION_FULL',
  SESSION_START: 'SESSION_START',
  USER_VIEW: 'USER_VIEW',
};
