import defaultAxiosInstance from '../services/axios';

class ThumbnailClient {
  constructor(axiosInstance = defaultAxiosInstance) {
    this.axios = axiosInstance;
  }

  getThumbnailBlob(thumbnailUrl) {
    return this.axios.get(thumbnailUrl, { responseType: 'blob' }).then((response) => response.data);
  }
}

export default ThumbnailClient;
