import React from 'react';
import copyToClipboard from 'copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import Modal from '../../Modal';
import Info from '../../Info';
import ActionItem from '../../../models/ActionItem';
import host from '../../../services/HostWrapper';

import defaultAxiosInstance from '../../../services/axios';
import { errorServiceDownImages } from '../../../img/error';
import useStoreUpdate from '../../../hooks/useStoreUpdate';
import useShallowStore from '../../../hooks/useShallowStore';

const Error4xx = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { isLoading, isErrorLoading, errorConfig, setIsErrorLoading, user } = useShallowStore((state) => ({
    isLoading: state.isLoading,
    isErrorLoading: state.isErrorLoading,
    errorConfig: state.errorConfig,
    setIsErrorLoading: state.setIsErrorLoading,
    user: state.user,
  }));
  const { generalUpdate } = useStoreUpdate();
  const exitFromErrorState = () => navigate(-1);
  const handleRetry = () => {
    setIsErrorLoading(true);
    if (!user) {
      navigate('/');
      return;
    }
    defaultAxiosInstance(errorConfig)
      .then((response) => {
        if (response.config.url.indexOf('/viewer/getshareurl') >= 0) {
          copyToClipboard(response.data);
        } else if (!isLoading) {
          generalUpdate();
        }
      })
      .catch(() => {})
      .then(exitFromErrorState);
  };

  const generateErrorTitle = () => {
    let actionInfo;
    if (errorConfig.url && errorConfig.method) {
      const url = errorConfig.url.toLowerCase();
      const method = errorConfig.method.toLowerCase();
      if (
        url.indexOf('/viewer/extendshare') >= 0 ||
        (url.indexOf('/viewer/v1/viewables/') >= 0 && url.indexOf('/extend') >= 0)
      ) {
        actionInfo = t('error-info-extend');
      } else if (url.indexOf('/viewer/getshareurl') >= 0 || url.indexOf('/share-url') >= 0) {
        actionInfo = t('error-info-copylink');
      } else if (url.indexOf('/viewer/deleteshare') >= 0 || method === 'delete') {
        actionInfo = t('error-info-delete');
      } else if (
        url.indexOf('/comments/v2/resources/') >= 0 ||
        (url.indexOf('/viewer/v1/comments/') >= 0 && url.indexOf('reply') === -1)
      ) {
        if (method === 'put') {
          actionInfo = t('error-info-resovled');
        } else if (method === 'post') {
          actionInfo = t('error-info-postcomment');
        }
      }
    }

    // Build the error info
    let errorInfo;
    if (actionInfo) {
      errorInfo = t('error-info-pre') + actionInfo + t('error-info-suf');
    } else {
      errorInfo = t('error-info-pre') + t('error-info') + t('error-info-suf');
    }

    return errorInfo;
  };

  const errorTitle = generateErrorTitle();
  return (
    <Modal handleExitClick={exitFromErrorState}>
      <Info
        img={errorServiceDownImages[theme.name]}
        title={errorTitle}
        msg={t('request-retry-description')}
        buttons={[new ActionItem(t('retry-now'), handleRetry)]}
        links={[
          new ActionItem(t('health-dashboard'), () => {
            host.launchBrowser('https://health.autodesk.com/');
          }),
        ]}
        refreshing={isErrorLoading}
      />
    </Modal>
  );
};

export default Error4xx;
