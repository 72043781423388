import defaultAxiosInstance from './axios';
import UserClient from '../clients/UserClient';

class UserService {
  constructor(axiosInstance = defaultAxiosInstance) {
    this.userClientInstance = new UserClient(axiosInstance);
  }

  getUserProfile() {
    return this.userClientInstance.getUserProfile();
  }
}

export default UserService;
