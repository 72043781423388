import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useRouteChange() {
  const location = useLocation();
  useEffect(() => {
    /* eslint-disable no-console */
    console.debug(`URL: ${window.location.href}`);
    console.debug(`App's state: ${window.location.host}${location.pathname}`);
  }, [location]);

  return null;
}
