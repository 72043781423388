import ViewerApiShareClient from './share/ViewerApiShareClient';
import ViewerApiCommentClient from './comment/ViewerApiCommentClient';
import ViewerApiUploadClient from './upload/ViewerApiUploadClient';

function selectShareApiClient(axiosInstance) {
  return new ViewerApiShareClient(axiosInstance);
}

function selectCommentApiClient(axiosInstance) {
  return new ViewerApiCommentClient(axiosInstance);
}

function selectUploaApiCLient(axiosInstance) {
  return new ViewerApiUploadClient(axiosInstance);
}

export default {
  selectShareApiClient,
  selectCommentApiClient,
  selectUploaApiCLient,
};
