import envConfig from 'environmentConfig';
import axios from 'axios';
import baseConfig from 'configs/envs/base.config.json';
import defaultAxiosInstance from '../../services/axios';
import { statusCodes } from '../../constants/uploadFileStatuses';
import { getCreateSessionUrl } from './utils';

class ViewerApiUploadClient {
  constructor(axiosInstance = defaultAxiosInstance, axiosCancelUploadConfig = {}) {
    this.axios = axiosInstance;
    this.axiosCancelUploadConfig = axiosCancelUploadConfig;
    this.viewerApiUrl = envConfig.get().viewerApi.url;
  }

  async createSession(filename) {
    const createSessionUrl = getCreateSessionUrl();
    const data = { filename };
    let session;
    try {
      session = await this.axios
        .post(createSessionUrl, data, this.axiosCancelUploadConfig)
        .then((response) => response.data);
    } catch (error) {
      throw new Error(statusCodes.CREATE_UPLOAD_SESSION_FAILED, { cause: error });
    }
    return session;
  }

  async getUploadUrl(objectName) {
    let signedUrlResp;
    try {
      const url = `${this.viewerApiUrl}${baseConfig.viewerApi.v1.seedFilesUpload}?objectName=${objectName}`;

      signedUrlResp = await this.axios
        .get(url, {
          ...this.axiosCancelUploadConfig,
        })
        .then((response) => ({ uploadUrl: response.data.url, uploadKey: response.data.uploadKey }));
    } catch (error) {
      throw new Error(statusCodes.GENERATE_SIGNED_URL_FAILED, { cause: error });
    }

    return signedUrlResp;
  }

  async uploadFile(uploadId, fileUrl, s3Url, uploadProgressConfig) {
    const axiosInstance = axios.create();
    let data;
    try {
      data = await axiosInstance
        .get(fileUrl, {
          responseType: 'blob',
          ...this.axiosCancelUploadConfig,
        })
        .then((response) => response.data);
    } catch (error) {
      throw new Error(statusCodes.RETRIEVE_LOCAL_FILE_FAILED, { cause: error });
    }

    try {
      await axiosInstance.put(s3Url, data, {
        onUploadProgress: uploadProgressConfig.onUploadProgress.bind(null, uploadId),
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        ...this.axiosCancelUploadConfig,
      });
    } catch (error) {
      throw new Error(statusCodes.S3_DIRECT_UPLOAD_FAILED, { cause: error });
    }
  }

  async completeUpload(objectName, uploadKey) {
    let uploadResponse;
    try {
      const url = `${this.viewerApiUrl}${baseConfig.viewerApi.v1.seedFilesUpload}`;
      const data = { objectName, uploadKey };

      uploadResponse = await this.axios.post(url, data, this.axiosCancelUploadConfig).then((response) => response.data);
    } catch (error) {
      throw new Error(statusCodes.COMPLETE_OSS_UPLOAD_FAILED, { cause: error });
    }

    return uploadResponse;
  }
}

export default ViewerApiUploadClient;
