import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { noSignedInImages } from '../../img/missing';
import host from '../../services/HostWrapper';
import UserService from '../../services/UserService';
import Info from '../Info';
import ActionItem from '../../models/ActionItem';
import { entitledVariations } from '../../constants/hostConfig';
import useShallowStore from '../../hooks/useShallowStore';
import useErrorHook from '../../hooks/useErrorHook';
import { getLDUser } from '../../utils/launchDarkly';

const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const handleError = useErrorHook();
  const { setUser, setLdUser } = useShallowStore((state) => ({
    setUser: state.setUser,
    setLdUser: state.setLdUser,
  }));
  const signInA360 = () => {
    host
      .signIn()
      .then(host.getIsEntitled)
      .then((isEntitled) =>
        new UserService()
          .getUserProfile()
          .then((userProfile) => {
            setUser(userProfile);
            return getLDUser(userProfile);
          })
          .then((ldUser) => setLdUser(ldUser))
          .then(() => isEntitled)
      )
      .then((isEntitled) => {
        const isEntitledCases = {
          [false]: () => navigate('usernotentitled'),
          [true]: () => navigate('app'),
          [entitledVariations.NOT_ENTITLED]: () => navigate('usernotentitled'),
          [entitledVariations.NOT_ASSIGNED]: () => navigate('usernotassigned'),
          [entitledVariations.ENTITLED]: () => navigate('app'),
          [entitledVariations.UNKNOWN]: () => navigate('unknownerror'),
        };
        isEntitledCases[isEntitled]();
      })
      .catch(handleError);
  };

  const checkHostLogin = () => {
    host
      .getIsLoggedIn()
      .then((isLoggedin) => {
        if (isLoggedin) {
          signInA360();
        } else {
          host.setLoginStatusChangedCallback(signInA360);
        }
      })
      .catch(handleError);
  };

  useEffect(() => {
    checkHostLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Info
      img={noSignedInImages[theme.name]}
      title={t('login-title')}
      handleTitleClick={signInA360}
      msg={t('login-message')}
      links={[new ActionItem(t('learn-shared-views'), host.launchHelp)]}
    />
  );
};

export default SignIn;
