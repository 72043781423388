import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import Modal from '../Modal';
import Info from '../../Info';
import ActionItem from '../../../models/ActionItem';
import useShallowStore from '../../../hooks/useShallowStore';
import useErrorHook from '../../../hooks/useErrorHook';
import adpInstance from '../../../utils/adp';
import { componentEvents, launchDarklyMetrics } from '../../../constants/analyticsConfig';
import shareService from '../../../services/ShareService';

const DeleteModal = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { shareToDelete, setShareToDelete } = useShallowStore((state) => ({
    shareToDelete: state.shareToDelete,
    setShareToDelete: state.setShareToDelete,
  }));
  const navigate = useNavigate();
  const handleError = useErrorHook();
  const ldClient = useLDClient();
  useEffect(() => {
    setIsDeleteModalOpen(Object.keys(shareToDelete).length > 0);
  }, [shareToDelete]);

  const closeDeleteModal = () => setShareToDelete({});
  const { t } = useTranslation();
  const deleteShare = () => {
    setIsDeleteModalOpen(false);
    if (shareToDelete && Object.keys(shareToDelete).length > 0) {
      shareService()
        .deleteShare(shareToDelete)
        .then(() => {
          ldClient?.track(launchDarklyMetrics.SHARE_DELETED);
          adpInstance.trackComponentEvent(componentEvents.SHARE_DELETED);
          navigate('/app');
        })
        .catch(handleError);
    }
  };

  return isDeleteModalOpen ? (
    <Modal handleExitClick={closeDeleteModal}>
      <Info
        title={t('delete-dialog-title')}
        msg={t('delete-dialog-content')}
        buttons={[
          new ActionItem(t('delete-dialog-cancel'), closeDeleteModal),
          new ActionItem(t('delete-dialog-ok'), deleteShare),
        ]}
      />
    </Modal>
  ) : null;
};

export default DeleteModal;
