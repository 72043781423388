import envConfig from 'environmentConfig';
import baseConfig from 'configs/envs/base.config.json';
import ShareModel from '../../models/ShareModel';
import defaultAxiosInstance from '../../services/axios';
import { createdAt, expiryDate, title } from '../../utils/sort';

class ViewerApiShareClient {
  constructor(axiosInstance = defaultAxiosInstance) {
    this.axios = axiosInstance;
    this.viewerApiUrl = envConfig.get().viewerApi.url;
  }

  createShareModelFromApiResponse(response) {
    return new ShareModel({
      title: response.displayName,
      createdAt: response.created,
      a360link: response.viewerUrl,
      status: response.status,
      lastUpdated: response.lastUpdated,
      expires: response.expiration,
      thumbnail: response.thumbnailUrl,
      numComments: response.commentCount,
      viewerId: response.viewableId,
      fileExtension: response.fileExtension,
    });
  }

  // Returns the sortBy parameter for API calls
  getAPISortBy = (sortBy) => {
    let retSortBy = 'created';
    switch (sortBy) {
      case createdAt:
        retSortBy = 'created';
        break;
      case title:
        retSortBy = 'name';
        break;
      case expiryDate:
        retSortBy = 'expiration';
        break;
      default:
        break;
    }
    return retSortBy;
  };

  buildGetShareParam(pageCount, sortBy, sortOrder) {
    // Default is Ascending
    const order = sortOrder === undefined ? 'Ascending' : sortOrder;
    if (sortBy === createdAt || sortBy === title || sortBy === expiryDate) {
      return `?page=${pageCount}&sortBy=${this.getAPISortBy(sortBy)}&sortOrder=${order}`;
    }
    return `?page=${pageCount}`;
  }

  buildGetSharesUrl(pageCount, sortBy, sortOrder) {
    let getSharesUrl = this.viewerApiUrl + baseConfig.viewerApi.v1.viewables;
    if (pageCount && !Number.isNaN(pageCount)) {
      getSharesUrl += this.buildGetShareParam(pageCount, sortBy, sortOrder);
    }
    return getSharesUrl;
  }

  getSharesApiResponse(getSharesUrl) {
    return this.axios.get(getSharesUrl);
  }

  getShares(pageCount, sortBy, sortOrder) {
    const getSharesUrl = this.buildGetSharesUrl(pageCount, sortBy, sortOrder);
    return this.getSharesApiResponse(getSharesUrl).then((response) => {
      return response.data.viewables.map(this.createShareModelFromApiResponse);
    });
  }

  getShareUrl(designShareID) {
    return this.axios
      .get(this.viewerApiUrl + baseConfig.viewerApi.v1.getViewableShareUrlById.replace('{id}', designShareID))
      .then((response) => response.data.shareUrl);
  }

  extendShare(designShareID) {
    return this.axios
      .post(this.viewerApiUrl + baseConfig.viewerApi.v1.extendViewableById.replace('{id}', designShareID))
      .then((response) => response.data.expiration);
  }

  deleteShare(designShareID) {
    return this.axios.delete(this.viewerApiUrl + baseConfig.viewerApi.v1.viewableById.replace('{id}', designShareID));
  }
}

export default ViewerApiShareClient;
