import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  maxHeight: theme.spacing(4),
}));

export const DropdownItem = styled('div')(({ theme }) => ({
  '&& .MuiTypography-root': {
    fontSize: '0.875rem',
  },
  paddingLeft: theme.spacing(0.5),
  svg: {
    fill: 'currentColor',
    fontSize: '1.25rem',
  },
}));
