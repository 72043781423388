/**
 * Dropdown renders
 * - If it's open: list of items
 * - Else: dropdown button
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import ActionItem from '../../models/ActionItem';
import { StyledMenuItem, DropdownItem } from './styled';

const Dropdown = ({ actionItems, button }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const close = (e) => {
    e.stopPropagation();

    setAnchorElement(null);
  };

  const open = (e) => {
    e.stopPropagation();

    setAnchorElement(e.currentTarget);
  };

  const handleItemClick = (e, action) => {
    e.stopPropagation();

    action.onClick(e);
    close(e);
  };

  return (
    <div>
      <IconButton data-testid="share-context-menu-button" onClick={open}>
        {button}
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        open={!!anchorElement}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={8}
        MenuListProps={{ disablePadding: true }}
      >
        {actionItems.map((action) => (
          <StyledMenuItem
            key={action.label}
            dense
            onClick={(e) => {
              handleItemClick(e, action);
            }}
          >
            <DropdownItem>{action.label}</DropdownItem>
          </StyledMenuItem>
        ))}
      </Menu>
    </div>
  );
};

Dropdown.propTypes = {
  actionItems: PropTypes.arrayOf(PropTypes.instanceOf(ActionItem)).isRequired,
  button: PropTypes.node.isRequired,
};

export default Dropdown;
