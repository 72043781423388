import PropTypes from 'prop-types';
import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, CloseModal } from './styled';

const Modal = ({ handleExitClick, children }) => (
  <Dialog open onClose={handleExitClick} maxWidth="md">
    <CloseModal data-testid="modal-close-icon-button" aria-label="close-button" onClick={handleExitClick}>
      <CloseIcon />
    </CloseModal>
    {children}
  </Dialog>
);

Modal.propTypes = {
  handleExitClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Modal;
