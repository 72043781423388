import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import envConfig from 'environmentConfig';
import host from './HostWrapper';
import newRelicHelper from '../utils/newRelic/helper';

const axiosInstance = axios.create();
const sessionId = uuidv4();

// Intercept http requests to add the OAuth2Token in the header
axiosInstance.interceptors.request.use(
  (config) =>
    host.getOAuth2Token().then((token) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    }),
  (err) => {
    newRelicHelper.trackError(err);
    Promise.reject(err);
  }
);

// Intercept http requests to ViewerApi to add analytic headers
axiosInstance.interceptors.request.use(
  (config) => {
    if (config?.url?.includes(envConfig.get()?.viewerApi?.url)) {
      config.headers['x-viewerapi-request-id'] = uuidv4();
      config.headers['x-viewerapi-source-app'] = 'SharedViews';
      config.headers['x-viewerapi-session-id'] = sessionId;
    }
    return config;
  },
  (err) => {
    newRelicHelper.trackError(err);
    Promise.reject(err);
  }
);

export default axiosInstance;
