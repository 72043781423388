import { getOxygenAnalyticsId } from './analytics';

export const getLDUser = async (userData) => {
  const ldUser = {};
  ldUser.key = await getOxygenAnalyticsId();
  ldUser.email = userData.email;
  ldUser.custom = {
    loggedIn: true,
  };

  return ldUser;
};
