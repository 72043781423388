import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import Info from '../../Info';
import ActionItem from '../../../models/ActionItem';
import host from '../../../services/HostWrapper';
import { errorServiceDownImages } from '../../../img/error';
import useStoreUpdate from '../../../hooks/useStoreUpdate';
import useShallowStore from '../../../hooks/useShallowStore';

const Error5xx = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { generalUpdate } = useStoreUpdate();
  const navigate = useNavigate();
  const { isLoading, isErrorLoading, setIsErrorLoading, user } = useShallowStore((state) => ({
    isLoading: state.isLoading,
    isErrorLoading: state.isErrorLoading,
    setIsErrorLoading: state.setIsErrorLoading,
    user: state.user,
  }));

  return (
    <Info
      img={errorServiceDownImages[theme.name]}
      title={t('request-refresh-discription')}
      msg={t('request-refresh')}
      buttons={[
        new ActionItem(t('error-info-refresh'), () => {
          setIsErrorLoading(true);
          if (user && !isLoading) {
            generalUpdate();
          } else {
            navigate('/');
          }
        }),
      ]}
      links={[
        new ActionItem(t('health-dashboard'), () => {
          host.launchBrowser('https://health.autodesk.com/');
        }),
      ]}
      refreshing={isErrorLoading}
    />
  );
};

export default Error5xx;
