import { styled } from '@mui/material/styles';

import MuiDivider from '@mui/material/Divider';

export const HeaderActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  minWidth: '60px',
  margin: theme.spacing(1),
}));

export const SearchWrapper = styled('div')(({ theme }) => ({
  flex: '1',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  marginLeft: theme.spacing(1),
}));

export const Divider = styled(MuiDivider)(({ theme }) => ({ margin: theme.spacing(1) }));
