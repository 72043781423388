import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';

/**
 * @template {{}} Props
 * @typedef {import('@emotion/styled').StyledComponent<Props>} StyledComponent<Props>
 */

/**
 * @type {StyledComponent<import('@mui/material').IconButtonProps>}
 */
export const BackButton = styled(IconButton)({ alignSelf: 'flex-start' });

/**
 * @type {StyledComponent<'div'>}
 */
export const NoCommentWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(2),
}));

/**
 * @type {StyledComponent<'div'>}
 */
export const CommentsViewShareWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
