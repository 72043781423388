import { styled } from '@mui/material/styles';
import MUICommentIcon from '@mui/icons-material/Comment';
import { Caption } from '../Text';

/**
 * @template {{}} Props
 * @typedef {import('@emotion/styled').StyledComponent<Props>} StyledComponent<Props>
 */

/**
 * @type {StyledComponent<'div'>}
 */
export const ShareTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}));

/**
 * @type {StyledComponent<'div'>}
 */
export const ShareContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
}));

/**
 * @type {StyledComponent<'div'>}
 */
export const ShareDescription = styled('div')(({ theme }) => ({
  flex: '3',
  marginLeft: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

/**
 * @type {StyledComponent<'div'>}
 */
export const ShareInfo = styled('div')({
  display: 'flex',
});

/**
 * @type {StyledComponent<'div'>}
 */
export const ShareImage = styled('div')({
  display: 'flex',
  width: '75px',
  height: '75px',
});

/**
 * @type {StyledComponent<'span'>}
 */
export const CommentsAmount = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
}));

/**
 * @type {StyledComponent<import('@mui/icons-material/Comment').default>}
 */
export const CommentIcon = styled(MUICommentIcon)({
  fontSize: 18,
});

/**
 * @type {StyledComponent<import('@mui/material/styles/createTypography').Typography>}
 */
export const ProcessingFailed = styled(Caption)(({ theme }) => ({
  color: theme.palette.warning.main,
}));
