import { styled } from '@mui/material/styles';
import { BodyText } from '../../Text';

/**
 * @template {{}} Props
 * @typedef {import('@emotion/styled').StyledComponent<Props>} StyledComponent<Props>
 */

/**
 * @type {StyledComponent<'div'>}
 */
export const ReplyWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(0),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

/**
 * @type {StyledComponent<import('@mui/material/styles/createTypography').Typography>}
 */
export const ReplyText = styled(BodyText)(() => ({
  overflowWrap: 'break-word',
}));
