import { sharesStatuses } from '../constants/shares';
import { ONE_MINUTE, MAX_TIME_COMPLEX_FILE_TYPE, MAX_TIME_SIMPLE_FILE_TYPE } from './constants';

export default function retry(callback, retryCount) {
  const initialInterval = 3;
  const exponential = 1.5;
  const nextRetry = initialInterval ** (exponential ** retryCount) * 1000;
  return setTimeout(() => {
    callback(retryCount + 1);
  }, nextRetry);
}

export const getTranslatingTime = (createdAt) => {
  const created = new Date(createdAt);
  return (Date.now() - created) / ONE_MINUTE;
};

export const getMaxTranslatingTime = (fileExtension) => {
  const simpleFileTypes = ['dwg', 'dwfx', 'step', 'dwf'];
  if (simpleFileTypes.indexOf(fileExtension.toLowerCase()) > -1) {
    return MAX_TIME_SIMPLE_FILE_TYPE;
  }
  return MAX_TIME_COMPLEX_FILE_TYPE;
};

export const shouldRetryFetching = ({ status, createdAt, fileExtension }, statusCode = 0) => {
  return (
    (statusCode >= 500 && statusCode < 599) ||
    (statusCode === 425 && getTranslatingTime(createdAt) < getMaxTranslatingTime(fileExtension)) ||
    (status === sharesStatuses.TRANSLATING && getTranslatingTime(createdAt) < getMaxTranslatingTime(fileExtension))
  );
};
