import ThumbnailClient from '../clients/ThumbnailClient';
import defaultAxiosInstance from './axios';

class ThumbnailService {
  constructor(axiosInstance = defaultAxiosInstance) {
    this.thumbnailClientInstance = new ThumbnailClient(axiosInstance);
  }

  getThumbnailBlob(thumbnailUrl) {
    return this.thumbnailClientInstance.getThumbnailBlob(thumbnailUrl);
  }
}

export default ThumbnailService;
