import { styled } from '@mui/material/styles';
import Input from '@mui/material/Input';

export const SearchWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: '1',
  alignItems: 'center',
  marginLeft: theme.spacing(2),
}));

export const SearchInput = styled(Input)({
  '&&.MuiInput-underline': {
    boxShadow: 'none',
  },
});
