/**
 * Collaborate renders the collaborate button
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined';
import { getCreateViewButtonStatus } from '../../utils/urlUtil';

import { createUI } from '../../constants/hostConfig';
import HiddenUploadBtn from './HiddenUploadBtn';
import { CollaborateWrapper, UploadShareButton } from './styled';
import useStoreUpdate from '../../hooks/useStoreUpdate';
import host from '../../services/HostWrapper';
import adpInstance from '../../utils/adp';
import { componentEvents, launchDarklyMetrics } from '../../constants/analyticsConfig';

const Collaborate = () => {
  const createViewButtonStatus = getCreateViewButtonStatus();
  const { t } = useTranslation();
  const { generalUpdate } = useStoreUpdate();
  const ldClient = useLDClient();
  const handleCollaborateClick = () => {
    ldClient.track(launchDarklyMetrics.UPLOAD_FILE);
    adpInstance.trackComponentEvent(componentEvents.UPLOAD_FILE);
    host.startCollaboration().then((refresh) => {
      if (refresh) generalUpdate();
    });
  };

  if (createViewButtonStatus === createUI.OFF) return null;
  return (
    <>
      <HiddenUploadBtn />
      <CollaborateWrapper>
        <UploadShareButton
          data-testid="panel-new-ds-btn-txt"
          variant="contained"
          disabled={createViewButtonStatus === createUI.DISABLED}
          onClick={handleCollaborateClick}
          size="large"
          startIcon={<CloudUploadIcon />}
        >
          {t('design-share-button')}
        </UploadShareButton>
      </CollaborateWrapper>
    </>
  );
};

export default Collaborate;
