import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

export const ModelThumbnailWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.grey[200],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: '0',
  left: '0',
  opacity: '0',
  transition: '0.25s ease',
  backgroundColor: theme.palette.background.default,
  '&:hover': {
    cursor: 'pointer',
    opacity: '0.5',
  },
}));

export const Image = styled('img')({
  position: 'relative',
  width: '100%',
});
