// Reply object
class ReplyModel {
  constructor({ author, text, createdAt }) {
    this.author = author;
    this.text = text;
    this.createdAt = createdAt;
  }
}

export default ReplyModel;
