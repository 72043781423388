import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from 'i18n.translations';
import moment from 'moment';

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lang',
};

i18n
  // detect user language
  .use(initReactI18next)
  .use(LanguageDetector)
  //  init i18next
  .init({
    resources,
    detection: options,
    fallbackLng: 'en', // use en if detected lng is not available
    debug: true,
    ns: ['panel'],
    react: {
      useSuspense: true,
    },
  });

moment.locale(i18n.language);

export default i18n;
