import { styled } from '@mui/material/styles';
import { BodyText } from '../Text';

export const InfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto 0px',
});

export const InfoImage = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const InfoActions = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: '40%',
  display: 'flex',
  justifyContent: 'space-around',
  gap: theme.spacing(1),
}));

export const InfoLink = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
});

export const InfoBodyText = styled(BodyText)(({ theme }) => ({
  margin: theme.spacing(1),
  textAlign: 'center',
}));

export const InfoLinkGroupWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
