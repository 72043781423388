import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { noSubscribedImages } from '../../../img/missing';
import host from '../../../services/HostWrapper';
import Info from '../../Info';
import ActionItem from '../../../models/ActionItem';

const UserNotEntitled = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleNotEntitledInfoClick = () => {
    host.launchBrowser(
      'https://www.autodesk.com/campaigns/maintenance-to-subscription?mktvar004=704254&internalc=true'
    );
  };
  return (
    <Info
      img={noSubscribedImages[theme.name]}
      title={t('not-entitled-title')}
      msg={t('not-entitled-info')}
      links={[
        new ActionItem(t('learn-shared-views'), host.launchHelp),
        new ActionItem(t('not-entitled-subscriber-benefits'), handleNotEntitledInfoClick),
      ]}
    />
  );
};

export default UserNotEntitled;
