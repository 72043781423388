import { useParams } from 'react-router-dom';
import useShallowStore from './useShallowStore';

const useCurrentShare = (viewableId) => {
  let { shareId } = useParams();
  if (!shareId && viewableId) {
    shareId = viewableId;
  }
  const { shares } = useShallowStore((state) => ({
    shares: state.shares,
  }));

  return shares.find((share) => share.viewerId === shareId);
};

export default useCurrentShare;
