import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import Reply from './Reply';
import useShallowStore from '../../../hooks/useShallowStore';
import useErrorHook from '../../../hooks/useErrorHook';

import { AddReply, CancelButton, CharLeft, CommentReplyInput, ReplyActions } from '../styled';
import adpInstance from '../../../utils/adp';
import { componentEvents, launchDarklyMetrics } from '../../../constants/analyticsConfig';
import commentService from '../../../services/CommentService';

/**
 * @typedef {import('../../../models/CommentModel').default} CommentModel
 */

/**
 *
 * @param {Object} props
 * @param {CommentModel} props.comment
 * @returns {JSX.Element}
 */
const ReplyList = ({ comment }) => {
  const maxReplyLength = 400;
  const [isReplying, setIsReplying] = useState(false);
  const [charLeft, setCharLeft] = useState(maxReplyLength);
  const [replyText, setReplyText] = useState('');

  const { user } = useShallowStore((state) => ({
    user: state.user,
  }));
  const handleError = useErrorHook();
  const ldClient = useLDClient();

  const exitReply = () => {
    setIsReplying(false);
    setCharLeft(maxReplyLength);
  };
  const startReply = () => setIsReplying(true);

  const onChangeReplyText = (e) => {
    // Set the text area max length because the HIG text area does not support max length
    if (e.target.maxLength === -1) {
      e.target.maxLength = maxReplyLength;
    }
    // Update the reply text and characters left
    setReplyText(e.target.value);
    setCharLeft(maxReplyLength - e.target.textLength);
  };
  const { t } = useTranslation();

  const replyButton = (
    <ReplyActions>
      <Button variant="contained" onClick={startReply} size="large">
        {t('comment-reply')}
      </Button>
    </ReplyActions>
  );

  const replyInput = (
    <AddReply>
      <CommentReplyInput
        name="comment-reply"
        multiline
        placeholder={t('comment-textarea-default')}
        onChange={onChangeReplyText}
        variant="outlined"
        minRows="3"
        maxRows="3"
        autoFocus
      />
      <CharLeft>{`${charLeft} ${t('comment-cha-left')}`}</CharLeft>
      <ReplyActions>
        <CancelButton variant="outlined" onClick={exitReply} size="large">
          {t('comment-back')}
        </CancelButton>
        <Button
          variant="contained"
          onClick={() => {
            ldClient?.track(launchDarklyMetrics.POST_REPLY);
            adpInstance.trackComponentEvent(componentEvents.POST_REPLY);
            commentService().postReply(comment, replyText, user).then(exitReply).catch(handleError);
          }}
          disabled={charLeft === maxReplyLength}
          size="large"
        >
          {t('comment-post')}
        </Button>
      </ReplyActions>
    </AddReply>
  );

  return (
    <>
      {comment.replies.map((reply) => (
        <Reply key={reply.createdAt} reply={reply} />
      ))}
      {isReplying ? replyInput : replyButton}
    </>
  );
};

export default ReplyList;
